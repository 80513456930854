import { post } from './axios'

// address
export const getReferer = (data = {}) => post('/v1/get_referer', data)

// address
export const getRefererCount = (data = {}) => post('/v1/get_referer_count', data)

// (address,token,token_id)
export const mint = (data = {}) => post('/v1/mint', data)

// (address,token)
export const invite = (data = {}) => post('/v1/invite', data)

// (address)
export const getRefererList = (data = {}) => post('/v1/get_referer_list', data)

