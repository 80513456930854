<template>
  <div class="main">
    <div class="section" style="margin-bottom: 0">
      <img src="@/assets/images/m_bg8.png" alt="" class="bg">
      <div
        class="title flex-center-center"
        style="margin-bottom: 0"
        data-aos="fade-up"
      >
        <img src="@/assets/images/main_title_img_1.png" alt="" />
        <span>FAQ</span>
        <img src="@/assets/images/main_title_img_2.png" alt="" />
      </div>
      <div class="sub-title" data-aos="fade-up" data-aos-delay="100">
        Find the anwsers to frequently asked <br /> questions about Spola PASS
      </div>
      <div class="section-content">
        <div class="faq-list">
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 0 ? (faqIndex = -1) : (faqIndex = 0);
              }
            "
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 0 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                1. How to buy it? Can I buy it by using fiat?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 0 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 0 ? '3.8rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Spola PASS is an ERC-721 token that building on the Ethereum blockchain, which means the fiat is not available here. But we will be glad to see you and be the lighthouse on your way to web3. Please join our Discord where we will provide completed tutorials and a comprehensive wiki for web3 rookies, hope you enjoy it there.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 1 ? (faqIndex = -1) : (faqIndex = 1);
              }
            "
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 1 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                2. What is the mint price?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 1 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 1 ? '1.4rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Round 1: 0.08 ETH<br />Round 2: 0.13 ETH
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 2 ? (faqIndex = -1) : (faqIndex = 2);
              }
            "
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 2 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                3. When to mint?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 2 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 2 ? '1.4rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Round 1 x 4000 : Nov 5, 8AM - Nov 8, 8AM (UTC+0)<br />Round 2 x 1000 : Nov 8, 8AM - Nov 10, 8AM (UTC+0)
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 3 ? (faqIndex = -1) : (faqIndex = 3);
              }
            "
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 3 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                4. How many sales queue?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 3 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 3 ? '2.6rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                There are 2 queues (Round 1 and Round 2) for sales, as we said above.<br />Round 1 will Launch on Bybit, Gate, Bitkeep, BKEX and our official mint page.<br />Round 2 will only launch on our official mint page.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 4 ? (faqIndex = -1) : (faqIndex = 4);
              }
            "
            data-aos="fade-up"
            data-aos-delay="350"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 4 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                5. Does Spoal PASS have a whitelist mechanism?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 4 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 4 ? '1rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                No.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 5 ? (faqIndex = -1) : (faqIndex = 5);
              }
            "
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 5 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                6. Which secondary marketplace will Spola PASS be listed on?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 5 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 5 ? '2.6rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Bybit<br />Gate<br />Bitkeep<br />Opensea<br />Element
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 6 ? (faqIndex = -1) : (faqIndex = 6);
              }
            "
            data-aos="fade-up"
            data-aos-delay="450"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 6 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                7. What is the IRL benefits details for each level?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 6 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 6 ? '3rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Platinum x 150: Signed Jersey + Equivalent of $50 Spola token airdrop<br />Gold   x 350: Tickets + Equivalent of $50 Spola token airdrop<br />Silver   x 1500: Equivalent of $50 Spola token airdrop<br />Bronze  x 3000: Equivalent of $20 Spola token airdrop
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 7 ? (faqIndex = -1) : (faqIndex = 7);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 7 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                8. How to Claim the IRL benefits?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 7 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 7 ? '1rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Redemption center will open soon.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 8 ? (faqIndex = -1) : (faqIndex = 8);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 8 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                9. What are secondary royalties and how will they be used?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 8 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 8 ? '2.6rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                If you trade Spola PASS on Opensea, we will receive 5% of all secondary sales. These proceeds will be used to expand our team.<br />If you trade Spola PASS on our collaborative CEX platform, we don’t charge any fee.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 9 ? (faqIndex = -1) : (faqIndex = 9);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 9 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                10. Is there any other chances to win signed jersey or tickets?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 9 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 9 ? '1.8rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Silver and Bronze PASS holders will be eligible to join the raffle to win an extra signed jersey and World Cup ticket.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 10 ? (faqIndex = -1) : (faqIndex = 10);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div :class="['flex-center-sb', 'faq-item-hd', faqIndex == 10 ? 'faq-item-hd-s' : '']">
              <div :class="['ask']">
                11. What if I don't want to keep my World Cup tickets?
              </div>
              <svg class="arraw" :style="{
                  transform: faqIndex == 10 ? 'rotate(180deg)' : 'rotate(0deg)',
                }" width="25px" height="14px" viewBox="0 0 25 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>路径 3备份</title>
                <g id="移动端" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.502069382" stroke-linecap="round" stroke-linejoin="round">
                  <g id="SPOLA-PASS-Mobile-UI--750px" transform="translate(-635.000000, -8034.000000)" stroke="#778AA2" stroke-width="2">
                    <g id="编组-65" transform="translate(45.087004, 7452.000000)">
                      <polyline id="路径-3备份" transform="translate(602.599288, 583.500000) rotate(135.000000) translate(-602.599288, -583.500000) " points="594.599288 575.5 610.599288 575.5 610.599288 591.5"></polyline>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 10 ? '2.6rem' : '0' }"
              @click.stop
            >
              <div class="answer-w">
                Buyback policy: considering the regional factor, Golder holder can waive their rights for the tickets from Nov 10th, 9AM to Nov 13th, 9AM (UTC+0). We will repurchase the ticket and refund you the cost price in USDT.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      faqIndex: -1
    };
  },
}
</script>
<style lang="scss" scoped>
.main {
  box-sizing: border-box;
  font-size: 0;
  text-align: center;
  z-index: 9;
  margin-top: 1.2rem;
  .section {
    position: relative;
    z-index: 2;
    .bg {
      position: absolute;
      top: 2.26rem;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
    }
    .title {
      margin-bottom: .6rem;
      img {
        width: .15rem;
        height: auto;
      }
      span {
        font-size: .32rem;
        font-family: DINPro-Bold, DINPro;
        color: #FFFFFF;
        line-height: .41rem;
        margin-left: .20rem;
        margin-right: .20rem;
        text-transform: uppercase;
      }
    }
    .sub-title {
      margin: auto;
      margin-top: .12rem;
      font-size: .24rem;
      color: #778AA2;
      line-height: .40rem;
    }
    .section-content {
      padding: 0 .45rem;
      box-sizing: border-box;
      margin: auto;
      margin-top: .3rem;
      .faq-list {
        margin: auto;
        .faq-item {
          margin-bottom: .2rem;
          cursor: pointer;
          .ask {
            font-size: .24rem;
            font-family: DINPro-Regular, DINPro;
            font-weight: 400;
            color: #778AA2;
            line-height: .32rem;
            flex: 0 0 5rem;
            text-align: left;
            &.ask-s {
              font-family: DINPro-Bold;
            }
          }
          .arraw {
            width: .25rem;
            height: auto;
            transition: all 0.3s;
            opacity: .5;
          }
          .faq-item-hd {
            padding: .17rem .45rem .22rem .53rem;
            box-sizing: border-box;
            // height: .41rem;
            border-radius: .21rem;
            background: rgba($color: #171619, $alpha: 0.6);
            &.faq-item-hd-s {
              background: #171619;
              .ask {
                font-family: DINPro-Bold;
              }
              .arraw {
                opacity: 1;
              }
            }
          }
          .answer {
            font-size: .24rem;
            color: #778AA2;
            line-height: .40rem;
            text-align: left;
            height: 0;
            transition: all 0.3s;
            overflow: hidden;
            .answer-w {
              padding: .3rem .3rem .3rem .53rem;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}
</style>