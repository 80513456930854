<template>
  <div class="mint" @touchmove.prevent @mousewheel.prevent>
    <div class="mint-content" >
      <div class="close flex-center-center" @click="close">CLOSE</div>
      <div class="title flex-center-center"><span>Spola PASS Mystery Box</span><div class="hint-icon"><img src="@/assets/images/hint_icon.svg" alt=""></div>
        <div class="hint-box">
          <img src="@/assets/images/hint_box.svg" alt="">
          <p>Mint Spola PASS now to get the premium IRL benefits including World Cup 2022 & $SPO and unlock massive privileges in Spola Labs.</p>
        </div>
      </div>
      <div class="progress">
        <div class="progress-line">
          <div 
            :class="['progress-activate', (nowTime > roundOneStartTime && nowTime < mintEndTime) ? 'progress-activate-after' : '']"
            :style="{width: (nowTime > roundOneStartTime && nowTime < roundTwoStartTime) ? '124px' : (nowTime > roundTwoStartTime && nowTime < mintEndTime) ? '400px' : (nowTime > mintEndTime && mintEndTime) ? '100%' : '90px'}"
          ></div>
          <div class="line-item line-1">
            <p :class="[(nowTime > roundOneStartTime && nowTime < roundTwoStartTime) ? 'p-activate' : '']"><img src="@/assets/images/hot_icon.svg" alt=""><span>Round 1</span> - Nov 5, 8:00 AM  (UTC+0)</p>
          </div>
          <div class="line-item line-2">
            <p :class="[(nowTime > roundTwoStartTime && nowTime < mintEndTime) ? 'p-activate' : '']"><img src="@/assets/images/hot_icon.svg" alt=""><span>Round 2</span> - Nov 8, 8:00 AM  (UTC+0)</p>
          </div>
        </div>
        <div class="close-hint-wrap">
          <div class="icon flex-center-sb"><i></i><i></i><i></i></div>
          <div class="close-hint">
            <img src="@/assets/images/close_hint.svg" alt="">
            <p><span>Mint close!</span><br /><span>Nov 10, 8:00 AM (UTC+0)</span></p>
          </div>
        </div>
      </div>
      <div v-if="mintStatus == 'loading'" class="section section-loading flex-center-center">
        <div class="loading"><span class="loader"></span></div>
        <div class="loading-title">loading...</div>
      </div>
      <div v-if="mintStatus == 'succeed'" class="section section-success flex-center-center">
        <img src="@/assets/images/mint_success.png" alt="" class="bg-img">
        <div class="success-title">congratulations!</div>
        <div class="success-sub-title">You have successfully purchased <span>{{tokenQuantity}} Spola PASS Mystery Box</span></div>
        <div class="success-box">
          <img src="@/assets/images/mystery_box.png" alt="">
          <div class="success-num">X <span>{{tokenQuantity}}</span></div>
        </div>
      </div>
      <div v-if="mintStatus == 'init'" class="section">
        <img src="@/assets/images/mystery_box.png" alt="">
        <div class="mint-progress flex-center">
          <p>Total <span>{{maxSupply ? maxSupply.toLocaleString() : '5,000'}}</span></p>
          <div class="mint-progress-line">
            <div class="mint-progress-activate" :style="{width: (totalSupply / maxSupply) * 100 + '%'}"><span v-if="totalSupply > 0">{{totalSupply.toLocaleString()}}</span></div>
          </div>
        </div>
        <div v-if="message" class="dialog flex-center-center">{{message}}</div>
      </div>
      <div class="mint-info flex-end" :style="{opacity: (nowTime > roundOneStartTime && nowTime < mintEndTime) ? '1' : '.5'}">
        <div class="mint-info-l flex-center">
          <button class="subtract mint-info-l-btn" @click="subtract" :disabled="!(nowTime > roundOneStartTime && nowTime < mintEndTime)">
            <svg t="1666286409045" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5507" width="18" height="18"><path d="M144 567c-24.3 0-44-19.7-44-44s19.7-44 44-44h736.127c24.3 0 44 19.7 44 44s-19.7 44-44 44H144z" p-id="5508" fill="#ffffff"></path></svg>
          </button>
          <div class="number">{{number}}</div>
          <button class="add mint-info-l-btn"  @click="add" :disabled="!(nowTime > roundOneStartTime && nowTime < mintEndTime)">
            <svg t="1666286370726" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4539" width="18" height="18"><path d="M911.244448 452.317713 563.184241 452.317713 563.184241 104.274902c0-27.465538-22.254863-49.720402-49.721425-49.720402-27.465538 0-49.719378 22.254863-49.719378 49.720402l0 348.043834L115.715977 452.318736c-27.465538 0-49.720402 22.254863-49.720402 49.721425 0 27.465538 22.254863 49.720402 49.720402 49.720402l348.028485 0 0 348.010065c0 27.467585 22.25384 49.722448 49.719378 49.722448 27.466562 0 49.721425-22.254863 49.721425-49.722448L563.185264 551.760562l348.060207 0c27.466562 0 49.720402-22.254863 49.720402-49.720402C960.96485 474.572576 938.71101 452.317713 911.244448 452.317713z" p-id="4540" fill="#ffffff"></path></svg>
          </button>
        </div>
        <div class="mint-info-r flex-end">
          <span>TOTAL PRICE</span>
          <p v-if="nowTime > roundOneStartTime && nowTime < roundTwoStartTime"><span>{{(firstRoundPrice * number).toFixed(2)}}</span>ETH</p>
          <p v-else-if="nowTime > roundTwoStartTime && nowTime < mintEndTime"><span>{{(secondRoundPrice * number).toFixed(2)}}</span>ETH</p>
          <p v-else><span>{{(0.08 * number).toFixed(2)}}</span>ETH</p>
        </div>
      </div>
      <div v-if="mintStatus == 'succeed'" @click="goOn" class="mint-btn mint-btn-continue flex-center-center">Continue</div>
      <button v-else-if="(nowTime > roundOneStartTime && nowTime < mintEndTime)" class="mint-btn mint-btn-activate flex-center-center" @click="mint(address)" :disabled="mintStatus == 'loading'">MINT NOW!</button>
      <div v-else class="mint-btn flex-center-center">not start yet</div>
      <div v-if="!balanceOf" class="owned flex-center-center">
        <p>no items</p>
      </div>
      <div v-else class="owned flex-center-sb">
        <div class="owned-l">Owned Mystery Box:</div>
        <div class="owned-r flex-center">
          <img src="@/assets/images/mystery_box.png" alt="">
          <p>X <span>{{balanceOf}}</span></p>
        </div>
      </div>
      <div class="brand flex-center-center">
        <div>
          <a href="https://www.bybit.com/en-US/nft/detail/?id=395958" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/bybi.png" alt="" style="width: 44px;height:auto">
            <img src="@/assets/images/bybi_s.png" alt="" style="width: 44px;height:auto">
          </a>
        </div>
        <div>
          <a href="https://www.gate.io/nft/collection/16102/Spola-PASS" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/gate.png" alt="">
            <img src="@/assets/images/gate_s.png" alt="">
          </a>
        </div>
        <div>
          <a href="https://bitkeep.com/en/download" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/bitkeep.png" alt="">
            <img src="@/assets/images/bitkeep_s.png" alt="">
          </a>
        </div>
        <div>
          <a href="https://www.bkex.com/" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/bkex.png" alt="">
            <img src="@/assets/images/bkex_s.png" alt="">
          </a>
        </div>
        <div>
          <img src="@/assets/images/opensea.png" alt="" style="width: 69px;height:auto">
          <img src="@/assets/images/opensea_s.png" alt="" style="width: 69px;height:auto">
        </div>
        <div>
          <img src="@/assets/images/element.png" alt="">
          <img src="@/assets/images/element_s.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { useStore } from 'vuex'
import { useContract } from '../hooks/useContract'
export default {
  setup(props, { emit }) {
    const store = useStore()
    const {
      roundOneStartTime,
      roundTwoStartTime,
      mintEndTime,
      firstRoundPrice,
      secondRoundPrice,
      totalSupply,
      maxSupply,
      mintStatus,
      tokenQuantity,
      balanceOf,
      message,
      number,
      subtract,
      add,
      setContract,
      mint,
      goOn
    } = useContract()
    const address = computed(() => {
      return store.state.address
    })
    const web3 = computed(() => {
      return store.state.web3
    })
    const network = computed(() => {
      return store.state.network
    })
    const clickEscCode = (e) => {
      if (e.keyCode === 27) {
        close()
      }
    }
    const close = () => {
      emit('closeMint')
    }

    watch(web3, async (value) => {
      setContract(value)
    }, {immediate: true})
    
    onMounted(() => {
      window.addEventListener('keydown', clickEscCode)
    })
    onUnmounted(() => {
      window.removeEventListener('keydown', clickEscCode)
    })
    return {
      number,
      address,
      network,
      roundOneStartTime,
      roundTwoStartTime,
      mintEndTime,
      firstRoundPrice,
      secondRoundPrice,
      totalSupply,
      maxSupply,
      mintStatus,
      tokenQuantity,
      balanceOf,
      message,
      nowTime: ref(Math.floor(new Date().getTime() / 1000)),
      subtract,
      add,
      close,
      mint,
      goOn
    }
  }
}
</script>
<style lang="scss" scoped>
.mint {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  .mint-content {
    padding: 16px 16px 28px;
    box-sizing: border-box;
    background: #171619;
    border: 1px solid #27262A;
    width: 600px;
    position: relative;
    .close {
      position: absolute;
      top: 6px;
      left: 10px;
      width: 73px;
      height: 27px;
      background: #101013;
      border-radius: 3px;
      font-size: 12px;
      color: #778AA2;
      line-height: 15px;
      cursor: pointer;
      border: 1px solid #27262A;
      z-index: 19;
      opacity: 1;
      &:hover {
        background: #171619;
      }
    }
    .title {
      text-align: center;
      position: relative;
      text-transform: uppercase;
      span {
        font-size: 24px;
        font-family: DINPro-Bold, DINPro;
        color: #FFFFFF;
        line-height: 31px;
        background: linear-gradient(295deg, #1C42FF 0%, #4BE9F2 50%, #CBFFEC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
      }
      .hint-icon {
        margin-left: 11px;
        img {
          width: 20px;
          height: 20px;
        }
        &:hover {
          & ~ .hint-box {
            display: block;
          }    
        }
      }
      .hint-box {
        display: none;
        position: absolute;
        bottom: -88px;
        right: 7px;
        z-index: 9;
        img {
          width: 245px;
          height: auto;
          margin-left: 0;
        }
        p {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          padding: 15px 16px 9px;
          box-sizing: border-box;
          font-size: 12px;
          color: #778AA2;
          line-height: 16px;
          text-transform: none;
        }
      }
    }
    .progress {
      margin: auto;
      margin-top: 22px;
      width: 540px;
      padding-right: 19px;
      height: 2px;
      box-sizing: border-box;
      position: relative;
      .progress-line {
        height: 2px;
        background: rgba($color: #778AA2, $alpha: .2);
        border-radius: 1px;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 0;
        .progress-activate {
          position: absolute;
          left: 0;
          // right: 0;
          top: 0;
          bottom: 0;
          z-index: 9;
          background: linear-gradient(132deg, rgba(28, 66, 255, 1), rgba(181, 0, 255, 1), rgba(255, 71, 71, 1));
          &.progress-activate-after {
            &::after {
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 6px;
              background: rgba(255, 71, 71, 1);
              position: absolute;
              right: -6px;
              top: -5px;
            }
          }
        }
        
        .line-item {
          width: 124px;
          height: 2px;
          position: relative;
          p {
            color: #778AA2;
            line-height: 32px;
            position: absolute;
            top: 5px;
            right: -104px;
            font-size: 14px;
            color: #778AA2;
            line-height: 32px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            &.p-activate {
              background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              img {
                display: inline;
              }
            }
            img {
              width: 15px;
              height: auto;
              margin-right: 5px;
              display: none;
            }
            span {
              font-family: DINPro-Bold;
            }
          }
          &::after {
            content: '';
            width: 08px;
            height: 08px;
            border-radius: 04px;
            background: #2A2D34;
            position: absolute;
            right: -04px;
            top: -03px;
          }
        }
        .line-1 {
          span {
            // color: #FF4747;
          }
          // &::after {
          //   display: none;
          // }
        }
        .line-2 {
          width: 276px;
        }
        &:hover {
          & ~ .close-hint-wrap {
            .close-hint {
              display: block;
            }
          }
        }
      }
      .close-hint-wrap {
        position: absolute;
        right: 0;
        top: -6px;
        z-index: 2;
        .icon {
          width: 13px;
          height: 13px;
          i {
            display: inline-block;
            background: #FF4747;
            border-radius: 2px;
            height: 13px;
            width: 2px;
          }
        }
        &:hover {
          .close-hint {
            display: block;
          }
        }
        .close-hint {
          position: absolute;
          bottom: -62px;
          right: -80px;
          display: none;
          img {
            width: 173px;
          }
          p {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            box-sizing: border-box;
            padding: 12px 0 9px;
            text-align: center;
            font-size: 0;
            span {
              font-size: 12px;
              color: #FFFFFF;
              line-height: 15px;
              &:first-child {
                font-size: 16px;
                font-family: DINPro-Bold, DINPro;
                color: #FF4747;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
    .section {
      height: 293px;
      margin-top: 47px;
      border-radius: 7px;
      border: 1px solid #27262A;
      box-sizing: border-box;
      padding: 16px 14px 4px;
      text-align: center;
      overflow: hidden;
      position: relative;
      .dialog {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin: auto;
        width: 418px;
        padding: 35px 20px;
        box-sizing: border-box;
        background: #171619;
        border: 1px solid #FF4747;
        font-size: 14px;
        font-family: DINPro-Bold, DINPro;
        font-weight: bold;
        color: #FF4747;
        line-height: 16px;
        text-transform: uppercase;
        z-index: 99;
      }
      img {
        width: 421px;
        height: auto;
      }
      .mint-progress {
        margin-top: 19px;
        p {
          font-size: 14px;
          color: #778AA2;
          line-height: 32px;
          span {
            font-family: DINPro-Bold;
          }
        }
        .mint-progress-line {
          margin-left: 15px;
          flex: 1;
          position: relative;
          height: 2px;
          background: rgba($color: #778AA2, $alpha: .2);
          border-radius: 2px;
        }
        .mint-progress-activate {
          background: #778AA2;
          border-radius: 2px;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 0;
          span {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 18px;
            font-size: 14px;
            font-family: DINPro-Bold, DINPro;
            color: #778AA2;
            top: -24px;
            right: -25px;
          }
          &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: #778AA2;
            position: absolute;
            right: -4px;
            top: -3px;
          }
        }
      }
      &.section-success {
        position: relative;
        padding: 34px 0;
        box-sizing: border-box;
        flex-direction: column;
        border-radius: 7px;
        border: 1px solid #1C42FF;
        overflow: hidden;
        .bg-img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          width: 100%;
          height: auto;
        }
        .success-title {
          font-size: 24px;
          font-family: DINPro-Bold, DINPro;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 31px;
          background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
        }
        .success-sub-title {
          font-size: 14px;
          color: #FFFFFF;
          line-height: 32px;
          span {
            font-family: DINPro-Bold, DINPro;
          }
        }
        .success-box {
          margin-top: 16px;
          position: relative;
          img {
            width: 290px;
            height: auto;
          }
          .success-num {
            position: absolute;
            bottom: 0;
            right: -45px;
            margin-left: 13px;
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 31px;
            span {
              font-family: DINPro-Bold, DINPro;
            }
          }
        }
      }
      &.section-loading {
        flex-direction: column;
        .loader {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: inline-block;
          border-top: 3px solid #FFF;
          border-right: 3px solid transparent;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .loading-title {
          margin-top: 18px;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 32px;
        }

      }
    }
    .mint-info {
      margin-top: 19px;
      justify-content: center;
      .mint-info-l {
        button {
          border: none;
          background: none;
          outline: none;
        }
        .number {
          width: 86px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          font-family: DINPro-Bold;
          color: #FFFFFF;
        }
        .mint-info-l-btn {
          cursor: pointer;
          width: 46px;
          height: 38px;
          background: #222125;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 23px;
            height: auto;
          }
          &[disabled] {
            cursor: not-allowed;
          }
        }
      }
      .mint-info-r {
        margin-left: 83px;
        margin-bottom: 2px;
        span {
          font-size: 14px;
          font-family: DINPro-Bold;
          color: #778AA2;
          line-height: 32px;
        }
        p {
          font-size: 20px;
          color: #FFFFFF;
          line-height: 26px;
          margin-left: 17px;
          span {
            font-size: 32px;
            font-family: DINPro-Bold, DINPro;
            color: #FFFFFF;
            line-height: 41px;
            margin-right: 6px;
          }
        }
      }
    }
    .mint-btn {
      background: none;
      border: none;
      display: flex;
      width: 100%;
      margin-top: 27px;
      height: 44px;
      border-radius: 7px;
      font-size: 20px;
      font-family: DINPro-Bold;
      line-height: 26px;
      background: #101013;
      color: #778AA2;
      opacity: .35;
      text-transform: uppercase;
      cursor: not-allowed;
      &.mint-btn-activate {
        background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
      }
      &.mint-btn-continue {
        background: #1C42FF;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
      }
    }
    .owned {
      margin-top: 13px;
      height: 114px;
      background: #101013;
      border-radius: 9px;
      border: 1px solid #27262A;
      padding: 11px 36px;
      box-sizing: border-box;
      p {
        font-size: 20px;
        font-weight: 400;
        color: #778AA2;
        line-height: 32px;
        text-align: center;
        opacity: .35;
        font-family: DINPro-Bold;
        text-transform: uppercase;
      }
      .owned-l {
        font-size: 20px;
        font-family: DINPro-Bold, DINPro;
        color: #778AA2;
        line-height: 32px;
      }
      .owned-r {
        img {
          width: 175px;
          height: auto;
          margin-right: 15px;
        }
        p {
          font-size: 24px;
          font-family: DINPro-Regular, DINPro;
          color: #778AA2;
          line-height: 31px;
          opacity: 1;
          span {
            font-family: 'DINPro-Bold';
          }
        }
      }
    }
    .brand {
      margin-top: 24px;
      div {
        cursor: pointer;
        margin-right: 21px;
        height: 20px;
        &:last-child {
          margin-right: 0;
        }
        a {
          display: inline-block;
        }
        img {
          height: 18px;
          width: auto;
          &:last-child {
            display: none;
          }
        }
        &:hover {
          img {
            &:first-child {
              display: none;
            }
            &:last-child {
            display: inline-block;
          }
          }
        }
      }
    }
  }
}
</style>