<template>
  <div class="ambassador">
    <div class="ambassador-content">
      <div class="ambassador-title">Ambassador Program</div>
      <div class="header flex-center-center">
        <div v-if="!address" class="not-address flex-center-center">
          Please connect your wallet...
        </div>
        <div v-if="address && !token" class="create-link flex-center-center" @click="isShowDialog = true">
          <img src="@/assets/images/link.svg" alt="">
          <span>Create Link</span>
        </div>
        <div v-if="address && token" class="promotion flex-center-sb">
          <img src="@/assets/images/ambassador_img1.png" alt="" class="img1">
          <div class="promotion-info">
            <p><span>Promotion Link：</span>{{path}}?referer={{token}}</p>
            <p style="margin-top: .14rem"><span>My Code：</span>{{token}}</p>
          </div>
          <div v-if="!copySuccess" class="copy flex-center-center" @click="copy(`${path}?referer=${token}`, token)">
            <img src="@/assets/images/copy.svg" alt="">
            <span>Copy</span>
          </div>
          <div v-if="copySuccess" class="copy flex-center-center" >
            <span>Already Copied!</span>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title flex-center-center" >
          <img src="@/assets/images/main_title_img_1.png" alt="">
          <span>Status</span>
          <img src="@/assets/images/main_title_img_2.png" alt="">
        </div>
        <div class="status flex-center-sb">
          <div class="status-item">
            <div class="flex-center-sb" :style="{opacity: inviteCount ? 1 : .5, 'flex-direction': 'column', height: '100%'}">
              <div class="status-item-hd flex-center-center">
                <img src="@/assets/images/invitees.svg" alt="" style="width: .27rem;margin-right:.14rem">
                <span>Invitees</span>
              </div>
              <div v-show="inviteCount" class="status-item-bd"><GlobalNumber :value="inviteCount" :time="1" /></div>
              <div v-show="!inviteCount" class="status-item-bd-not">Data unavailable...</div>
            </div>
          </div>
          <div class="status-item">
            <div class="flex-center-sb" :style="{opacity: mintCount ? 1 : .5, 'flex-direction': 'column', height: '100%'}">
              <div class="status-item-hd flex-center-center">
                <img src="@/assets/images/minted_invitees.svg" alt="" style="width: .34rem;margin-right:.07rem">
                <span>Total Minted</span>
              </div>
              <div v-show="mintCount" class="status-item-bd"><GlobalNumber :value="mintCount" :time="1" /></div>
              <div v-show="!mintCount" class="status-item-bd-not">Data unavailable...</div>
            </div>
          </div>
          <div class="status-item">
            <div class="flex-center-sb" style="opacity: .5;flex-direction: column;height:100%">
              <div class="status-item-hd flex-center-center">
                <img src="@/assets/images/commission.svg" alt="" style="width: .28rem;margin-right:.14rem">
                <span>Commission</span>
              </div>
              <div class="status-item-bd-not">Data unavailable...</div>
            </div>
          </div>
          <div class="status-item">
            <div class="flex-center-sb" style="opacity: .5;flex-direction: column;height:100%">
              <div class="status-item-hd flex-center-center">
                <img src="@/assets/images/token.svg" alt="" style="width: .31rem;margin-right:.11rem">
                <span>Spola Token</span>
              </div>
              <div class="status-item-bd-not">Data unavailable...</div>
            </div>
          </div>
        </div>
        <div class="border"></div>
        <div class="referer-list">
          <div class="referer-item" v-for="(item, index) in refererList" :key="index">
            <div class="item-mint">
              <img v-if="item.mint_count" src="@/assets/images/referer_mint.svg" alt="">
            </div>
            <div class="item-date referer-item-text">{{formatDate(item.created_time)}} (UTC)</div>
            <div class="item-user">
              <img src="@/assets/images/user.svg" alt="">
              <p class="referer-item-text">{{item.address}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="ft flex-center-center">
        <a href="https://discord.gg/J3uKnUtbSM" target="_blank"><img src="@/assets/images/discord.svg" alt="" /></a>
        <a href="https://twitter.com/SpolaLabs" target="_blank"><img src="@/assets/images/twitter.svg" alt="" /></a>
        <p>© 2022 Spola Labs PTE. LTD. All Rights Reserved.</p>
      </div>
      <img src="@/assets/images/amb_bg3.png" alt="" class="bg4">
    </div>
    <div class="bg">
      <img src="@/assets/images/header_bg.png" alt="" class="bg1">
      <img src="@/assets/images/amb_bg1.png" alt="" class="bg2">
      <img src="@/assets/images/amb_bg2.png" alt="" class="bg3">
    </div>
    <div class="dialog flex-start" v-if="isShowDialog">
      <div class="dialog-l">
        <img src="@/assets/images/dialog_info.svg" alt="">
      </div>
      <div class="dialog-r">
        <div class="diaolg-text">
          <p>Each Address can only generate 1 promotion link, after generation, your address will be labeled by “Inviter”and you will get 1 exclusive promotion link +  1 invite code.</p>
          <p style="margin-top: .3rem">Are you sure to create?</p>
        </div>
        <div class="dialog-ft flex-center-sb">
          <div class="confirm-btn flex-center-center" @click="bindGetReferer">Confirm</div>
          <div class="cancel-btn flex-center-center" @click="isShowDialog = false">Cancel</div>
        </div>
      </div>
    </div>
    <!-- <div class="notify" v-show="copySuccess">
      <p>Copy successful</p>
    </div> -->
  </div>
</template>
<script>
import { getReferer, invite, getRefererCount, getRefererList } from '../http/api'
import { computed, watch, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import GlobalNumber from '../components/GlobalNumber.vue'
export default {
  components: {
    GlobalNumber
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const formatAddress = ref('')
    const token = ref('')
    const routerQuery = router.currentRoute.value.query
    const refererCode = routerQuery.referer
    const inviteCount = ref(0)
    const mintCount = ref(0)
    const refererList = ref([])
    const isShowDialog = ref(false)
    const copySuccess = ref(false)
    const web3 = computed(() => {
      return store.state.web3
    })
    const network = computed(() => {
      return store.state.network
    })
    const address = computed(() => {
      return store.state.address || ''
    })
    const formatDate = computed(() => {
      return (time) => {
        console.log(time)
        let date = new Date()
        if (time) {
          date = new Date(time * 1000)
        }
        let UTCMonth = date.getUTCMonth() + 1
        if (UTCMonth < 10) UTCMonth = '0' + UTCMonth
        let UTCDate = date.getUTCDate()
        if (UTCDate < 10) UTCDate = '0' + UTCDate
        let UTCHours = date.getUTCHours()
        if (UTCHours < 10) UTCHours = '0' + UTCHours
        let UTCMinutes = date.getUTCMinutes()
        if (UTCMinutes < 10) UTCMinutes = '0' + UTCMinutes
        let UTCSeconds = date.getUTCSeconds()
        if (UTCSeconds < 10) UTCSeconds = '0' + UTCSeconds
        return `${UTCHours}:${UTCMinutes}:${UTCSeconds} ${UTCMonth}-${UTCDate}`
      }
    })
    const getTokenList = () => {
      let tokenList = localStorage.getItem('tokenList')
      if (tokenList) tokenList = JSON.parse(tokenList)
      else tokenList = []
      return tokenList
    }
    const bindGetReferer = () => {
      if (address.value) {
        getReferer({address: address.value}).then(res => {
          console.log(res)
          if (res.code == 0) {
            let tokenList = getTokenList()
            tokenList.push({address: address.value, token: res.token})
            localStorage.setItem('tokenList', JSON.stringify(tokenList))
            token.value = res.token
          }
          isShowDialog.value = false
        })
      } else {
        connectWallet()
      }
    }
    const connectWallet = () => {
      store.commit("getWeb3")
    }
    const copy = (path, token) => {
      let value = `Promotion Link: ${path}\r\nMy Code: ${token}`
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value)
        copySuccess.value = true
        setTimeout(() => {
          copySuccess.value = false
        }, 2000)
      }
    }
    watch(address, () => {
      console.log(address.value)
      if (address.value) {
        if (refererCode ) {
          invite({address: address.value, token: refererCode}).then(res => {
            console.log(res)
            if (res.code == 0) {
              router.push({
                query: {}
              })
            }
          })
          // mint({address: address.value, token: refererCode, token_id: 1}).then(res => {
          //   console.log(res)
          // })
        }
        getRefererCount({address: address.value}).then(res => {
          console.log(res)
          if (res.code == 0) {
            inviteCount.value = res.invite_count
            mintCount.value = res.mint_count
          }
        })
        getRefererList({address: address.value}).then(res => {
          if (res.code == 0) {
            refererList.value = res.referer_list || []
          }
        })
        let tokenList = getTokenList()
        let tokenData = tokenList.filter(e => e.address == address.value)
        if (tokenData && tokenData[0]) {
          token.value = tokenData[0].token
        } else {
          token.value = ''
        }
      } else {
        inviteCount.value = 0
        mintCount.value = 0
      }
    }, { immediate: true })
    return {
      web3,
      network,
      formatAddress,
      address,
      token,
      inviteCount,
      mintCount,
      isShowDialog,
      copySuccess,
      refererList,
      path: ref(`${location.origin}${location.pathname}`),
      bindGetReferer,
      copy,
      formatDate
    }
  },
}
</script>
<style lang="scss" scoped>
.ambassador {
  position: relative;
  .ambassador-content {
    width: 12.8rem;
    margin: auto;
    padding-top: .8rem;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    .ambassador-title {
      margin: .3rem auto;
      font-size: .32rem;
      font-family: DINPro-Bold;
      color: #FFFFFF;
      line-height: .41rem;
      background: linear-gradient(299deg, #1C42FF 0%, #4BE9F2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      text-transform: uppercase;
    }
    .header {
      width: 100%;
      height: 1.36rem;
      background: #171619;
      border-radius: .3rem;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: -1px;
        right: -1px;
        top: -1px;
        bottom: -1px;
        background: linear-gradient(299deg, rgba(28, 66, 255, 1), rgba(75, 233, 242, 1));
        z-index: 1;
        border-radius: .3rem;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #171619;
        z-index: 2;
        border-radius: .3rem;
      }
      .promotion {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 3;
        .img1 {
          width: 3.17rem;
          height: auto;
          position: absolute;
          bottom: 0;
          left: .31rem;
        }
        .promotion-info {
          margin-left: 3.64rem;
          p {
            font-size: .18rem;
            font-family: DINPro-Regular;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .23rem;
            span {
              font-size: .18rem;
              font-family: DINPro-Bold;
              color: #778AA2;
              line-height: .23rem;
              background: linear-gradient(299deg, #1C42FF 0%, #4BE9F2 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .copy {
          width: 1.76rem;
          height: .65rem;
          background: rgba($color: #101013, $alpha: 0.5);
          border-radius: .10rem;
          margin-right: .47rem;
          cursor: pointer;
          img {
            width: .17rem;
            height: .17rem;
          }
          span {
            font-size: .16rem;
            font-family: DINPro-Regular;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .21rem;
            margin-left: .07rem;
          }
        }
      }
      .not-address {
        font-size: .16rem;
        color: #778AA2;
        line-height: .21rem;
        position: relative;
        z-index: 3;
        width: 4.32rem;
        height: .38rem;
        border-radius: .10rem;
      }
      .create-link {
        font-size: .18rem;
        font-family: DINPro-Bold;
        color: #FFFFFF;
        line-height: .32rem;
        position: relative;
        z-index: 3;
        width: 4.32rem;
        height: .38rem;
        background: #1C42FF;
        border-radius: .10rem;
        cursor: pointer;
        img {
          width: .25rem;
          height: auto;
          margin-right: .08rem;
        }
      }
    }
    .section {
      margin-top: .3rem;
      background: rgba($color: #171619, $alpha: 0.85);
      border-radius: .3rem;
      font-size: 0;
      padding: .3rem 0 .7rem;
      box-sizing: border-box;
      .title {
        margin-bottom: .4rem;
        img {
          width: .15rem;
          height: auto;
        }
        span {
          font-size: .32rem;
          font-family: DINPro-Bold;
          color: #FFFFFF;
          line-height: .42rem;
          margin-left: .2rem;
          margin-right: .2rem;
          text-transform: uppercase;
        }
      }
      .status {
        padding: 0 .45rem;
        box-sizing: border-box;
        .status-item {
          flex-direction: column;
          width: 2.81rem;
          height: 1.47rem;
          background: rgba($color: #101013, $alpha: 0.6);
          border-radius: .17rem;
          padding: .24rem 0;
          box-sizing: border-box;
          .status-item-hd {
            span {
              font-size: .18rem;
              font-family: DINPro-Bold;
              color: #778AA2;
              line-height: .23rem;
              text-transform: uppercase;
            }
            img {
              height: auto;
            }
          }
          .status-item-bd {
            font-size: .48rem;
            font-family: DINPro-Bold;
            color: #FFFFFF;
            line-height: .61rem;
            letter-spacing: 1px;
            background: linear-gradient(299deg, #1C42FF 0%, #4BE9F2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
          }
          .status-item-bd-not {
            font-size: .16rem;
            font-family: DINPro-Regular;
            font-weight: 400;
            color: #778AA2;
            line-height: .21rem;
            padding-bottom: .22rem;
          }
        }
      }
      .border {
        margin: .6rem .47rem;
        height: 1px;
        background: #778AA2;
        opacity: .1;
      }
      .referer-list {
        .referer-item {
          // width: 7rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          margin-bottom: .22rem;
          .item-mint {
            width: .61rem;
            height: .17rem;
            img {
              width: 100%;
              height: auto;
            }
          }
          .item-date {
            margin-left: .2rem;
            margin-right: .2rem;
          }
          .item-user {
            display: flex;
            align-items: center;
            width: 4.75rem;
            img {
              width: .18rem;
              height: auto;
              margin-right: 5px;
            }
          }
          .referer-item-text {
            font-size: 18px;
            font-weight: 400;
            color: #778AA2;
            line-height: 23px;
          }
        }
      }
    }
    .ft {
      padding: .4rem 0;
      box-sizing: border-box;
      a {
        opacity: .3;
        font-size: 0;
        & ~ a {
          margin-left: .3rem;
        }
        &:hover {
          opacity: 1;
        }
      }
      img {
        width: .26rem;
        height: auto;
        cursor: pointer;
      }
      p {
        opacity: .4;
        margin-left: .5rem;
        font-size: .16rem;
        font-family: DINPro-Regular;
        font-weight: 400;
        color: #778AA2;
        line-height: .32rem;
      }
    }
    .bg4 {
      position: absolute;
      right: -.35rem;
      bottom: .26rem;
      width: 2.43rem;
      height: auto;
    }
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    .bg1 {
      width: 19.2rem;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
    .bg2 {
      width: 7.42rem;
      height: auto;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
    .bg3 {
      width: 6.64rem;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }
  .notify {
    box-sizing: border-box;
    position: fixed;
    top: 1rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    p {
      padding: .12rem .35rem;
      display: inline-block;
      background: #171619;
      font-size: .18rem;
      font-family: DINPro-Regular;
      font-weight: 400;
      color: #FFFFFF;
      line-height: .32rem;
      border-radius: .1rem;
    }
  }
  .dialog {
    padding: .28rem .35rem .41rem;
    position: fixed;
    top: 2.46rem;
    left: 0;
    right: 0;
    width: 5.71rem;
    height: 3.02rem;
    background: #171619;
    border-radius: .17rem;
    margin: auto;
    z-index: 999;
    font-size: 0;
    box-sizing: border-box;
    .dialog-l {
      margin-top: .07rem;
      margin-right: .2rem;
      flex: 0 0 .22rem;
      img {
        width: .22rem;
        height: auto;
      }
    }
    .diaolg-text {
      flex: 1;
      p {
        font-size: .18rem;
        font-family: DINPro-Regular;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .32rem;
      }
    }
    .dialog-ft {
      margin-top: .28rem;
      font-size: .18rem;
      font-family: DINPro-Bold;
      color: #FFFFFF;
      .confirm-btn {
        width: 2.65rem;
        height: .45rem;
        background: #1C42FF;
        border-radius: 8px;
        cursor: pointer;
      }
      .cancel-btn {
        width: 1.65rem;
        height: .45rem;
        background: #101013;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.ambassador {
  .ambassador-content {
    .number-grow {
      font-size: .48rem;
      font-family: DINPro-Bold;
      color: #FFFFFF;
      line-height: .61rem;
      letter-spacing: 1px;
      background: linear-gradient(299deg, #1C42FF 0%, #4BE9F2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
}
  }
}
</style>