<template>
  <div class="main">
    <div class="section" style="margin-bottom: 0">
      <div
        class="title flex-center-center"
        style="margin-bottom: 0"
        data-aos="fade-up"
      >
        <img src="@/assets/images/main_title_img_1.png" alt="" />
        <span>FAQ</span>
        <img src="@/assets/images/main_title_img_2.png" alt="" />
      </div>
      <div class="sub-title" data-aos="fade-up" data-aos-delay="100">
        Find the anwsers to frequently asked questions about Spola PASS
      </div>
      <div class="section-content">
        <div class="faq-list">
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 0 ? (faqIndex = -1) : (faqIndex = 0);
              }
            "
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 0 ? 'ask-s' : '']">
                1. How to buy it? Can I buy it by using fiat?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 0 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 0 ? '184px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Spola PASS is an ERC-721 token that building on the Ethereum blockchain, which means the fiat is not available here. But we will be glad to see you and be the lighthouse on your way to web3. Please join our Discord where we will provide completed tutorials and a comprehensive wiki for web3 rookies, hope you enjoy it there.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 1 ? (faqIndex = -1) : (faqIndex = 1);
              }
            "
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 1 ? 'ask-s' : '']">
                2. What is the mint price?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 1 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 1 ? '120px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Round 1: 0.08 ETH<br />Round 2: 0.13 ETH
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 2 ? (faqIndex = -1) : (faqIndex = 2);
              }
            "
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 2 ? 'ask-s' : '']">
                3. When to mint?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 2 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 2 ? '120px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Round 1 x 4000 : Nov 5, 8AM - Nov 8, 8AM (UTC+0)<br />Round 2 x 1000 : Nov 8, 8AM - Nov 10, 8AM (UTC+0)
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 3 ? (faqIndex = -1) : (faqIndex = 3);
              }
            "
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 3 ? 'ask-s' : '']">
                4. How many sales queue?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 3 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 3 ? '152px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                There are 2 queues (Round 1 and Round 2) for sales, as we said above.<br />Round 1 will Launch on Bybit, Gate, Bitkeep, BKEX and our official mint page.<br />Round 2 will only launch on our official mint page.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 4 ? (faqIndex = -1) : (faqIndex = 4);
              }
            "
            data-aos="fade-up"
            data-aos-delay="350"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 4 ? 'ask-s' : '']">
                5. Does Spoal PASS have a whitelist mechanism?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 4 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 4 ? '88px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                No.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 5 ? (faqIndex = -1) : (faqIndex = 5);
              }
            "
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 5 ? 'ask-s' : '']">
                6. Which secondary marketplace will Spola PASS be listed on?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 5 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 5 ? '216px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Bybit<br />Gate<br />Bitkeep<br />Opensea<br />Element
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 6 ? (faqIndex = -1) : (faqIndex = 6);
              }
            "
            data-aos="fade-up"
            data-aos-delay="450"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 6 ? 'ask-s' : '']">
                7. What is the IRL benefits details for each level?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 6 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 6 ? '184px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Platinum x 150: Signed Jersey + Equivalent of $50 Spola token airdrop<br />Gold   x 350: Tickets + Equivalent of $50 Spola token airdrop<br />Silver   x 1500: Equivalent of $50 Spola token airdrop<br />Bronze  x 3000: Equivalent of $20 Spola token airdrop
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 7 ? (faqIndex = -1) : (faqIndex = 7);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 7 ? 'ask-s' : '']">
                8. How to Claim the IRL benefits?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 7 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 7 ? '54px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Redemption center will open soon.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 8 ? (faqIndex = -1) : (faqIndex = 8);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 8 ? 'ask-s' : '']">
                9. What are secondary royalties and how will they be used?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 8 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 8 ? '154px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                If you trade Spola PASS on Opensea, we will receive 5% of all secondary sales. These proceeds will be used to expand our team.<br />If you trade Spola PASS on our collaborative CEX platform, we don’t charge any fee.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 9 ? (faqIndex = -1) : (faqIndex = 9);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 9 ? 'ask-s' : '']">
                10. Is there any other chances to win signed jersey or tickets?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 9 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 9 ? '120px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Silver and Bronze PASS holders will be eligible to join the raffle to win an extra signed jersey and World Cup ticket.
              </div>
            </div>
          </div>
          <div
            class="faq-item"
            @click="
              () => {
                faqIndex == 10 ? (faqIndex = -1) : (faqIndex = 10);
              }
            "
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div class="flex-center-sb faq-item-hd">
              <div :class="['ask', faqIndex == 10 ? 'ask-s' : '']">
                11. What if I don't want to keep my World Cup tickets?
              </div>
              <img
                src="@/assets/images/arraw.png"
                alt=""
                class="arraw"
                :style="{
                  transform: faqIndex == 10 ? 'rotate(180deg)' : 'rotate(0deg)',
                }"
              />
            </div>
            <div
              class="answer"
              :style="{ height: faqIndex == 10 ? '154px' : '0' }"
              @click.stop
            >
              <div
                style="padding: 14px 51px 42px; box-sizing: border-box"
              >
                Buyback policy: considering the regional factor, Golder holder can waive their rights for the tickets from Nov 10th, 9AM to Nov 13th, 9AM (UTC+0). We will repurchase the ticket and refund you the cost price in USDT.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      faqIndex: -1
    };
  },
}
</script>
<style lang="scss" scoped>
.main {
  margin-top: -100px;
  box-sizing: border-box;
  font-size: 0;
  text-align: center;
  z-index: 9;
  .section {
    margin-bottom: 180px;
    position: relative;
    z-index: 2;
    .title {
      margin-bottom: 50px;
      img {
        width: 15px;
        height: auto;
      }
      span {
        font-size: 32px;
        font-family: DINPro-Bold;
        color: #ffffff;
        line-height: 42px;
        margin-left: 20px;
        margin-right: 20px;
        text-transform: uppercase;
      }
    }
    .sub-title {
      margin: auto;
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      color: #778aa2;
      line-height: 32px;
      margin-bottom: 50px;
      width: 442px;
    }
    .section-content {
      width: 890px;
      margin: auto;
      .faq-list {
        // height: 531px;
        width: 800px;
        margin: auto;
        .faq-item {
          width: 800px;
          margin-bottom: 8px;
          cursor: pointer;
          &:hover {
            // .ask {
            //   font-family: DINPro-Bold;
            // }
            .faq-item-hd {
              padding: 0 29px 0 51px;
              box-sizing: border-box;
              height: 41px;
              border-radius: 21px;
              background: rgba($color: #171619, $alpha: 1);
            }
          }
          .ask {
            font-size: 16px;
            font-weight: 400;
            color: #778aa2;
            line-height: 32px;
            transition: all 0.3s;
            &.ask-s {
              font-family: DINPro-Bold;
            }
          }
          .arraw {
            width: 14px;
            height: auto;
            transition: all 0.3s;
          }
          .faq-item-hd {
            padding: 0 29px 0 51px;
            box-sizing: border-box;
            height: 41px;
            border-radius: 21px;
            background: rgba($color: #171619, $alpha: 0.6);
          }
          .answer {
            font-size: 16px;
            font-weight: 400;
            color: #94a8c1;
            line-height: 32px;
            text-align: left;
            // background: #101013;
            height: 0;
            transition: all 0.3s;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>