import { ref, toRaw, computed } from "vue";
import { contractData } from "../config/config.js";
import { contract } from "../libs/connectWallet";
import { useStore } from 'vuex'
import { ethers } from 'ethers'
export const useContract = () => {
  let C = null
  const store = useStore()
  const roundOneStartTime = ref(0)
  const roundTwoStartTime = ref(0)
  const mintEndTime = ref(0)
  const firstRoundPrice = ref(0)
  const secondRoundPrice = ref(0)
  const totalSupply = ref(0)
  const maxSupply = ref(0)
  const mintStatus = ref('init')
  const tokenQuantity = ref(0)
  const balanceOf = ref(0)
  const message = ref('')
  const number = ref(1)
  const address = computed(() => {
    return store.state.address
  })

  const network = computed(() => {
    return store.state.network
  })

  const contractChainId = computed(() => {
    return store.state.contractChainId
  })

  const subtract = () => {
    if (number.value > 1) {
      number.value -= 1
    }
  }
  const add = () => {
    number.value += 1
  }

  const getRoundOneStartTime = async () => {
    try {
      roundOneStartTime.value = +ethers.utils.formatUnits(await C.roundOneStartTime(), 0)
    } catch (error) {
      console.error(error)
    }
  }

  const getRoundTwoStartTime = async () => {
    try {
      roundTwoStartTime.value = +ethers.utils.formatUnits(await C.roundTwoStartTime(), 0)
    } catch (error) {
      console.error(error)
    }
  }

  const getMintEndTime = async () => {
    try {
      mintEndTime.value = +ethers.utils.formatUnits(await C.mintEndTime(), 0)
    } catch (error) {
      console.error(error)
    }
  }

  const getMaxSupply = async () => {
    try {
      maxSupply.value = +ethers.utils.formatUnits(await C.maxSupply(), 0)
    } catch (error) {
      console.error(error)
    }
  }

  const getFirstRoundPrice = async () => {
    try {
      let price = await C.firstRoundPrice()
      firstRoundPrice.value = ethers.utils.formatEther(price)
    } catch (error) {
      console.error(error)
    }
  }

  const getSecondRoundPrice = async () => {
    try {
      let price = await C.secondRoundPrice()
      secondRoundPrice.value = ethers.utils.formatEther(price)
    } catch (error) {
      console.error(error)
    }
  }

  const getTotalSupply = async () => {
    try {
      totalSupply.value = +ethers.utils.formatUnits(await C.totalSupply(), 0)
    } catch (error) {
      console.error(error)
    }
  }

  const getBalanceOf = async () => {
    try {
      let balance = await C.balanceOf(address.value)
      balanceOf.value = +ethers.utils.formatUnits(balance, 0)
      store.commit('setBalanceOf', balanceOf.value)
    } catch (error) {
      console.error(error)
    }
  }

  const clearMessage = () => {
    setTimeout(() => {
      message.value = ''
    }, 3000)
  }

  const mint = async (address) => {
    let nowDate = Math.floor(new Date().getTime() / 1000)
    let tx
    mintStatus.value = 'loading'
    if ((nowDate > mintEndTime.value) || (nowDate < roundOneStartTime.value)) {
      mintStatus.value = 'init'
      return
    } else if (nowDate > roundOneStartTime.value && nowDate < roundTwoStartTime.value) {
      try {
        tx = await C.mintFirstRound(number.value, {from: address, value: ethers.utils.parseUnits((firstRoundPrice.value * number.value).toString(), 18).toString()})
      } catch (error) {
        console.error(error)
        mintStatus.value = 'init'
        message.value = 'error: ' + error.error.message
        clearMessage()
        return
      }
    } else if (nowDate > roundTwoStartTime.value && nowDate < mintEndTime.value) {
      try {
        tx = await C.mintSecondRound(number.value, {from: address, value: ethers.utils.parseUnits((secondRoundPrice.value * number.value).toString(), 18).toString()})
      } catch (error) {
        mintStatus.value = 'init'
        message.value = 'error: ' + error.error.message
        clearMessage()
        return
      }
    }
    console.log(tx)
    await tx.wait()
    mintStatus.value = 'succeed'
    tokenQuantity.value = number.value
    setTimeout(() => {
      getBalanceOf()
      getTotalSupply()
    }, 500)
  }

  const goOn = () => {
    mintStatus.value = 'init'
    tokenQuantity.value = 0
    number.value = 1
  }

  const setContract = async(value) => {
    if (contractChainId.value != network.value.chainId) return
    let user = toRaw(value).getSigner()
    C = await contract(contractData.contractAddress, contractData.abi, user)
    getRoundOneStartTime()
    getRoundTwoStartTime()
    getMintEndTime()
    getFirstRoundPrice()
    getSecondRoundPrice()
    getTotalSupply()
    getMaxSupply()
    getBalanceOf()
  }
  return {
    roundOneStartTime,
    roundTwoStartTime,
    mintEndTime,
    firstRoundPrice,
    secondRoundPrice,
    totalSupply,
    maxSupply,
    mintStatus,
    tokenQuantity,
    balanceOf,
    message,
    number,
    setContract,
    mint,
    goOn,
    subtract,
    add
  }
}
