<template>
  <div class="main">
    <div class="content">
      <div class="banner">
        <a href="https://spolalabs.com/blog/2" target="_blank" rel="noopener noreferrer">
          <img src="@/assets/images/banner.png" alt="">
        </a>
      </div>
      <div class="section" style="margin-bottom: 96px">
        <div class="section-content flex-center" style="margin-top: 9px">
          <div class="section-l">
            <img
              src="@/assets/images/pass_card.png"
              alt=""
              data-aos="fade-zoom-in"
              data-aos-offset="0"
              data-aos-delay="800"
            />
            <img
              src="@/assets/images/pass_card_1.png"
              alt=""
              data-aos="fade-zoom-in"
              data-aos-offset="0"
              data-aos-delay="600"
            />
            <img
              src="@/assets/images/pass_card_2.png"
              alt=""
              data-aos="fade-zoom-in"
              data-aos-offset="0"
              data-aos-delay="400"
            />
            <img
              src="@/assets/images/pass_card_3.png"
              alt=""
              data-aos="fade-zoom-in"
              data-aos-offset="0"
              data-aos-delay="200"
            />
          </div>
          <div class="section-r">
            <p
              class="section-r-sub-title"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Exclusive Collection
            </p>
            <br />
            <p class="section-r-title" data-aos="fade-up" data-aos-delay="100">
              Spola PASS NFT
            </p>
            <p
              class="section-r-content"
              style="margin-top: 9px"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              The unique digital portrait and the crucial PASS throughout our
              Spola Ecosystem, holders will receive IRL benefits from it.
            </p>
          </div>
        </div>
        <div class="section-content flex-start" style="margin-top: 73px">
          <!-- <img src="@/assets/images/main_section_img3.png" alt="" class="section-l-img3" data-aos="fade-up" data-aos-delay="300"> -->
          <div class="section-r" style="margin-top: 4px">
            <p class="section-r-title" data-aos="fade-up" data-aos-delay="100">
              Realistic Utility Grants the Privilege
            </p>
            <p
              class="section-r-content"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              IRL benefits are the core of Spola PASS. We serve as sports
              managers and capture various rights of sports management for
              members in real life.
            </p>
            <!-- <p class="section-more" data-aos="fade-up" data-aos-delay="300">
              <span>Learn more</span>
              <img src="@/assets/images/more_arrow.svg" alt="" />
            </p> -->
          </div>
          <div class="section-rr flex-center-sb" data-aos="fade-up" data-aos-delay="300">
            <img src="@/assets/images/main_section_img1.png" alt="" :style="{opacity: el1Count >= 1 ? '.6' : '.2'}" />
            <img src="@/assets/images/main_section_img2.png" alt="" :style="{opacity: el1Count >= 2 ? '.6' : '.2'}" />
            <img src="@/assets/images/main_section_img3.png" alt="" :style="{opacity: el1Count >= 3 ? '.6' : '.2'}" />
            <div class="section-rr-ft" :style="{width: el1Count * 33 + '%'}">
              <img src="@/assets/images/indicate.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title flex-center-center" data-aos="fade-up">
          <img src="@/assets/images/main_title_img_1.png" alt="" />
          <span>You will get</span>
          <img src="@/assets/images/main_title_img_2.png" alt="" />
        </div>
        <div class="section-content">
          <div
            class="section-content-box section-content-box-world flex-center"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src="@/assets/images/main_section_img4.png"
              alt=""
              data-aos="fade-up"
              data-aos-delay="400"
            />
            <div class="section-r" style="left: -52px">
              <p
                class="section-r-title"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                World Cup 2022
              </p>
              <p
                class="section-r-content"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                -Ticket including semi-finals/finals<br />-AMA with superstar<br />-Offline
                activity<br />-Signed jersey and photo
              </p>
            </div>
          </div>
        </div>
        <div
          class="section-content flex-start-center"
          style="margin-top: 20px; padding-bottom: 29px"
        >
          <div
            class="
              section-content-box
              section-content-box-airdrop
              section-content-box1
            "
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src="@/assets/images/main_section_img5.svg"
              alt=""
              class="box-bg"
              data-aos="fade-up"
              data-aos-delay="400"
            />
            <div class="box-content">
              <p
                class="section-box-title"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Airdrop
              </p>
              <p
                class="section-box-content"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                -$Spola token airdrop <br />-Collaborative partners token
                airdrop
              </p>
            </div>
          </div>
          <div
            class="
              section-content-box
              section-content-box-priority
              section-content-box1
            "
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="box-content">
              <p
                class="section-box-title"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Priority
              </p>
              <p
                class="section-box-content"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                -Spola originated NFT whitelist and price discount<br />-Founding
                member of club DAO<br />-More...
              </p>
              <img
                src="@/assets/images/main_section_img6.png"
                alt=""
                class="box-img"
                data-aos="fade-up"
                data-aos-delay="400"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section" style="z-index: 3">
        <div
          class="title flex-center-center"
          style="margin-bottom: 0"
          data-aos="fade-up"
        >
          <img src="@/assets/images/main_title_img_1.png" alt="" />
          <span>BUT HOW?</span>
          <img src="@/assets/images/main_title_img_2.png" alt="" />
        </div>
        <div class="section-content-1 flex-start-center">
          <div class="section-l" data-aos="fade-up" data-aos-delay="400">
            <img src="@/assets/images/main_section_img7.png" alt="" />
          </div>
          <div class="section-r">
            <p
              class="section-box-title"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Gain Massive IRL Benefit <br />
              from Mystery Box
            </p>
            <p
              class="section-box-content"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              By purchasing a mystery box, you can obtain one OG PASS with a
              specific level randomly.
            </p>
            <div class="section-btn" data-aos="fade-up" data-aos-delay="400" @click="mint">
              MINT NOW!
            </div>
          </div>
        </div>
      </div>
      <div class="section" style="margin-bottom:0">
        <div class="section-levels">
          <div class="section-levels-hd" :style="{opacity: scrollTop > 5560 ? '0' : 1,}">
            <div class="section-levels-hd-center flex-start">
              <div class="section-levels-hd-l">
                <p class="flex-center section-levels-hd-title" data-aos="fade-up">
                  <span></span>OG PASS is composed of 4 levels
                </p>
                <p data-aos="fade-up" data-aos-delay="200">
                  All the benefits will be allocated to the four levels
                  proportionally.
                </p>
                <p class="flex-center levels" data-aos="fade-up" data-aos-delay="400">
                  <span class="flex-start"><span></span> - Platinum</span>
                  <span class="flex-start"><span></span> - Gold</span>
                  <span class="flex-start"><span></span> - Silver</span>
                  <span class="flex-start"><span></span> - Bronze</span>
                </p>
              </div>
              <div class="section-levels-hd-r">
                <p class="flex-center section-levels-hd-title" data-aos="fade-up">
                  <span></span>Total supply of 5,000
                </p>
                <div class="section-levels-hd-r-item flex-center" id="el2" data-aos="fade-up" data-aos-delay="200">
                  <p>Round One: 4,000</p>
                  <div class="section-levels-hd-r-p">
                    <div class="section-levels-hd-r-p-a" ></div>
                  </div>
                </div>
                <div class="section-levels-hd-r-item flex-center" id="el3" data-aos="fade-up" data-aos-delay="400">
                  <p>Round Two: 1,000</p>
                  <div class="section-levels-hd-r-p">
                    <div class="section-levels-hd-r-p-a" ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section-main flex-start">
            <div class="section-main-l">
              <div class="section-item">
                <div class="section-item-l">
                  <p class="section-item-title section-item-title-1" data-aos="fade-up" >
                    OG - BRONZE
                  </p>
                  <div class="section-item-l-div flex-center" id="el4" data-aos="fade-up" data-aos-delay="200">
                    <p>Total release <span>3000</span></p>
                    <div class="section-item-p">
                      <div class="section-item-p-a section-item-p-a-1"></div>
                    </div>
                  </div>
                  <div class="section-item-l-div flex-center" data-aos="fade-up" data-aos-delay="400">
                    <p class="flex-center">Holder Benefits:</p>
                    <div class="section-item-l-div-p flex-center">
                      <img src="@/assets/images/icon_1.svg" alt="" />
                      <p><span>$20 SPO</span> token airdrop</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-item">
                <div class="section-item-l">
                  <p class="section-item-title section-item-title-2" data-aos="fade-up" >
                    OG - SILVER
                  </p>
                  <div class="section-item-l-div flex-center" id="el5" data-aos="fade-up" data-aos-delay="200">
                    <p>Total release <span>1500</span></p>
                    <div class="section-item-p">
                      <div class="section-item-p-a section-item-p-a-2"></div>
                    </div>
                  </div>
                  <div class="section-item-l-div flex-center" data-aos="fade-up" data-aos-delay="400">
                    <p class="flex-center">Holder Benefits:</p>
                    <div class="section-item-l-div-p flex-center">
                      <img src="@/assets/images/icon_1.svg" alt="" />
                      <p><span>$50 SPO</span> token airdrop</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-item">
                <div class="section-item-l" style="top: 312px;">
                  <p class="section-item-title section-item-title-3" data-aos="fade-up">OG - GOLD</p>
                  <div class="section-item-l-div flex-center" id="el6" data-aos="fade-up" data-aos-delay="200">
                    <p>Total release <span>350</span></p>
                    <div style="margin-left: 23px" class="section-item-p">
                      <div class="section-item-p-a section-item-p-a-3"></div>
                    </div>
                  </div>
                  <div class="section-item-l-div flex-center" data-aos="fade-up" data-aos-delay="300">
                    <p class="flex-center">Holder Benefits:</p>
                    <div class="section-item-l-div-p flex-center">
                      <img src="@/assets/images/icon_1.svg" alt="" />
                      <p><span>$50 SPO</span> token airdrop</p>
                    </div>
                  </div>
                  <div class="section-item-l-div flex-center" data-aos="fade-up" data-aos-delay="400">
                    <div
                      class="section-item-l-div-p flex-center"
                      style="margin-left: 0"
                    >
                      <img src="@/assets/images/icon_2.svg" alt="" />
                      <p><span>Qatar World Cup 2022 Tickets x 1</span></p>
                    </div>
                  </div>
                  <img
                    src="@/assets/images/section_img9.png"
                    data-aos="fade-up" data-aos-delay="500"
                    alt=""
                    class="section-item-l-img"
                  />
                </div>
              </div>
              <div class="section-item" style="height: 719px">
                <div class="section-item-l" style="top: 416px">
                  <p class="section-item-title section-item-title-4" data-aos="fade-up">
                    OG - PLATINUM
                  </p>
                  <div class="section-item-l-div flex-center" id="el7" data-aos="fade-up" data-aos-delay="200">
                    <p>Total release <span>150</span></p>
                    <div style="margin-left: 23px" class="section-item-p">
                      <div class="section-item-p-a section-item-p-a-4"></div>
                    </div>
                  </div>
                  <div class="section-item-l-div flex-start" data-aos="fade-up" data-aos-delay="400">
                    <p class="flex-center">Holder Benefits:</p>
                    <div>
                      <div class="section-item-l-div-p flex-center">
                        <img src="@/assets/images/icon_1.svg" alt="" />
                        <p><span>$50 SPO</span> token airdrop</p>
                      </div>
                      <div
                        class="section-item-l-div-p flex-center"
                        style="margin-top: 22px"
                      >
                        <img src="@/assets/images/icon_3.svg" alt="" />
                        <p><span>Signed Jersey</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-item" style="height:200px;">
                <div class="section-item-l" style="position: static;"></div>
              </div>
            </div>
            <div class="section-main-r">
              <div class="section-item section-item-sticky">
                <div class="section-item-r">
                  <img
                    data-aos="fade-up" 
                    src="@/assets/images/pass_1.png"
                    alt=""
                    :style="{width: '563px', opacity: scrollTop > 3760 ? '.3' : 1, transform: scrollTop > 3060 ? 'scale(1)' : 'scale(1.1)'}"
                  />
                </div>
                <img src="@/assets/images/bg3.png" alt="" class="bg-3" />
              </div>
              <div class="section-item section-item-sticky">
                <div class="section-item-r">
                  <img
                    src="@/assets/images/pass_3.png"
                    data-aos="fade-up" 
                    alt=""
                    :style="{width: '549px', opacity: scrollTop > 4500 ? '.3' : 1, transform: scrollTop > 3760 ? 'scale(1)' : 'scale(1.1)'}"
                  />
                </div>
              </div>
              <div class="section-item section-item-sticky">
                <div class="section-item-r">
                  <img
                    src="@/assets/images/pass_2.png"
                    data-aos="fade-up" 
                    alt=""
                    style="width: 533px"
                    :style="{width: '533px', opacity: scrollTop > 5190 ? '.3' : 1, transform: scrollTop > 4500 ? 'scale(1)' : 'scale(1.1)'}"
                  />
                </div>
              </div>
              <div class="section-item section-item-sticky">
                <div class="section-item-r">
                  <img
                    src="@/assets/images/pass_4.png"
                    data-aos="fade-up" 
                    alt=""
                    style="width: 511px"
                    :style="{width: '511px', transform: scrollTop > 5190 ? 'scale(1)' : 'scale(1.1)'}"
                  />
                </div>
              </div>
              <div class="section-item section-item-sticky" style="height: 200px">
                <div class="section-item-r"></div>
              </div>
            </div>
            <!-- <div class="section-item flex-center-sb">
              <div class="section-item-l"></div>
              <div class="section-item-r"></div>
            </div> -->
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { throttle } from '../../libs/utils'
export default {
  name: "Main",
  data() {
    return {
      faqIndex: -1,
      el1Count: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      isShow: false,
      rotate: false,
      scrollTop: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', throttle(() => {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
    }, 90))
    let el1 = document.querySelector('.section-rr')
    let el2 = document.querySelector('#el2')
    let el3 = document.querySelector('#el3')
    let el4 = document.querySelector('#el4')
    let el5 = document.querySelector('#el5')
    let el6 = document.querySelector('#el6')
    let el7 = document.querySelector('#el7')
    el1.addEventListener("webkitTransitionEnd", () => {
      if (this.end) return
      this.end = true
      this.el1Count = 1
      // console.log(1)
      setTimeout(() => {
        this.el1Count = 2
        setTimeout(() => {
          this.el1Count = 3
        }, 250)
      }, 250)
    }, true)
    el1.addEventListener("oTransitionEnd", () => {
      if (this.end) return
      this.end = true
      this.el1Count = 1
      // console.log(1)
      setTimeout(() => {
        this.el1Count = 2
        setTimeout(() => {
          this.el1Count = 3
        }, 250)
      }, 250)
    }, true)
    el1.addEventListener("transitionEnd", () => {
      if (this.end) return
      this.end = true
      this.el1Count = 1
      // console.log(1)
      setTimeout(() => {
        this.el1Count = 2
        setTimeout(() => {
          this.el1Count = 3
        }, 250)
      }, 250)
    }, true)
    el2.addEventListener("webkitTransitionEnd", () => {
      el2.querySelector('.section-levels-hd-r-p-a').style.width = "80%";
    })
    el2.addEventListener("oTransitionEnd", () => {
      el2.querySelector('.section-levels-hd-r-p-a').style.width = "80%";
    })
    el2.addEventListener("transitionEnd", () => {
      el2.querySelector('.section-levels-hd-r-p-a').style.width = "80%";
    })
    el3.addEventListener("webkitTransitionEnd", () => {
      el3.querySelector('.section-levels-hd-r-p-a').style.width = "20%";
    })
    el3.addEventListener("oTransitionEnd", () => {
      el3.querySelector('.section-levels-hd-r-p-a').style.width = "20%";
    })
    el3.addEventListener("transitionEnd", () => {
      el3.querySelector('.section-levels-hd-r-p-a').style.width = "20%";
    })
    el4.addEventListener("webkitTransitionEnd", () => {
      el4.querySelector('.section-item-p-a-1').style.width = "182px";
    })
    el4.addEventListener("oTransitionEnd", () => {
      el4.querySelector('.section-item-p-a-1').style.width = "182px";
    })
    el4.addEventListener("transitionEnd", () => {
      el4.querySelector('.section-item-p-a-1').style.width = "182px";
    })
    el5.addEventListener("webkitTransitionEnd", () => {
      el5.querySelector('.section-item-p-a-2').style.width = "93px";
    })
    el5.addEventListener("oTransitionEnd", () => {
      el5.querySelector('.section-item-p-a-2').style.width = "93px";
    })
    el5.addEventListener("transitionEnd", () => {
      el5.querySelector('.section-item-p-a-2').style.width = "93px";
    })
    el6.addEventListener("webkitTransitionEnd", () => {
      el6.querySelector('.section-item-p-a-3').style.width = "50px";
    })
    el6.addEventListener("oTransitionEnd", () => {
      el6.querySelector('.section-item-p-a-3').style.width = "50px";
    })
    el6.addEventListener("transitionEnd", () => {
      el6.querySelector('.section-item-p-a-3').style.width = "50px";
    })
    el7.addEventListener("webkitTransitionEnd", () => {
      el7.querySelector('.section-item-p-a-4').style.width = "16px";
    })
    el7.addEventListener("oTransitionEnd", () => {
      el7.querySelector('.section-item-p-a-4').style.width = "16px";
    })
    el7.addEventListener("transitionEnd", () => {
      el7.querySelector('.section-item-p-a-4').style.width = "16px";
    })
  },
  methods: {
    mint() {
      this.$emit('mint')
    }
  },
};
</script>
<style lang="scss" scoped>
.main {
  box-sizing: border-box;
  font-size: 0;
  text-align: center;
  z-index: 9;
  .banner {
    position: relative;
    z-index: 9;
    margin-top: 44px;
    margin-bottom: 53px;
    a {
      display: inline-block;
    }
    img {
      width: 890px;
      height: auto;
      margin: auto;
    }
  }
  .section {
    margin-bottom: 180px;
    position: relative;
    z-index: 2;
    .title {
      margin-bottom: 50px;
      img {
        width: 15px;
        height: auto;
      }
      span {
        font-size: 32px;
        font-family: DINPro-Bold;
        color: #ffffff;
        line-height: 42px;
        margin-left: 20px;
        margin-right: 20px;
        text-transform: uppercase;
      }
    }
    .sub-title {
      margin: auto;
      margin-top: 16px;
      font-size: 20px;
      font-weight: 400;
      color: #778aa2;
      line-height: 32px;
      margin-bottom: 50px;
      width: 442px;
    }
    .section-content {
      width: 890px;
      margin: auto;
      .section-l {
        position: relative;
        width: 369px;
        height: 341px;
        margin-right: 86px;
        img {
          width: 327px;
          height: 196px;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          z-index: 4;
          &:nth-child(2) {
            width: 354px;
            height: 254px;
            z-index: 3;
          }
          &:nth-child(3) {
            width: 369px;
            height: 303px;
            z-index: 2;
          }
          &:nth-child(4) {
            width: 369px;
            height: 341px;
            z-index: 1;
          }
        }
      }
      .section-r {
        width: 390px;
        text-align: left;
        position: relative;
        .section-r-sub-title {
          display: inline-block;
          font-size: 16px;
          color: #778aa2;
          line-height: 21px;
          background: linear-gradient(299deg, #1c42ff 0%, #4be9f2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .section-r-title {
          display: inline-block;
          font-size: 32px;
          font-family: DINPro-Bold;
          color: #ffffff;
          line-height: 41px;
          background: linear-gradient(
            295deg,
            #1c42ff 0%,
            #4be9f2 50%,
            #cbffec 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
        }
        .section-r-content {
          font-size: 18px;
          color: #778aa2;
          line-height: 32px;
          margin-top: 16px;
        }
        .section-more {
          margin-top: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            font-size: 18px;
            font-family: DINPro-Bold;
            color: #1c42ff;
            line-height: 32px;
            background: linear-gradient(301deg, #1c42ff 0%, #4be9f2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          img {
            width: 7px;
            margin-left: 12px;
            position: relative;
            left: 0;
            transition: all 0.3s;
            margin-top: 4px;
          }
          &:hover {
            img {
              left: 8px;
            }
          }
        }
      }
      .section-rr {
        width: 435px;
        margin-left: 63px;
        position: relative;
        img {
          width: 135px;
          height: auto;
          opacity: 0.2;
          transition: all .25s linear;
        }
        .section-rr-ft {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 426px;
          transition: all .25s linear;
          height: 1px;
          background: linear-gradient(
            299deg,
            rgba(110, 195, 255, 1),
            rgba(28, 66, 255, 0)
          );
          img {
            position: absolute;
            right: -17px;
            bottom: 0;
            width: 118px;
            height: auto;
            opacity: 1;
          }
          &::after {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background: #6ec3ff;
            position: absolute;
            right: -4px;
            top: -3px;
          }
        }
      }
      .section-content-box {
        background: rgba($color: #171619, $alpha: 0.6);
        border-radius: 34px;
        padding: 44px 44px 39px 44px;
        margin: auto;
        img {
          flex: 0 0 496px;
          width: 496px;
          height: auto;
          transition: all 0.3s;
        }
        &.section-content-box-world {
          &:hover {
            img {
              animation: float2 2s linear infinite;
            }
          }
        }
        &.section-content-box-priority {
          &:hover {
            .box-img {
              transform: translateY(-10px) scale(1.05);
              animation: float1 0.3s linear;
            }
          }
        }
        &.section-content-box-airdrop {
          &:hover {
            img {
              animation: float3 2s linear infinite;
            }
          }
        }
      }
      .section-content-box1 {
        width: 434px;
        height: 344px;
        box-sizing: border-box;
        position: relative;
        padding: 0;
        .box-bg {
          width: 390px;
          height: auto;
        }
        .box-content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 26px 60px;
          box-sizing: border-box;
        }
        & ~ .section-content-box1 {
          margin-left: 20px;
        }
        .section-box-title {
          font-size: 32px;
          font-family: DINPro-Bold, DINPro;
          color: #ffffff;
          line-height: 41px;
          background: linear-gradient(305deg, #1c42ff 0%, #4be9f2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
        }
        .section-box-content {
          font-size: 18px;
          font-family: DINPro-Regular, DINPro;
          font-weight: 400;
          color: #778aa2;
          line-height: 32px;
          margin-top: 16px;
        }
        .box-img {
          margin-top: 17px;
          width: 282px;
          height: auto;
        }
      }
    }
    .section-content-1 {
      margin-top: 74px;
      .section-l {
        width: 469px;
        img {
          width: 469px;
          height: auto;
        }
      }
      .section-r {
        margin-top: 1px;
        width: 518px;
        text-align: left;
        margin-left: 41px;
        .section-box-title {
          font-size: 32px;
          font-family: DINPro-Bold, DINPro;
          color: #ffffff;
          line-height: 41px;
          background: linear-gradient(305deg, #1c42ff 0%, #4be9f2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
          display: inline-block;
        }
        .section-box-content {
          font-size: 20px;
          font-weight: 400;
          color: #778aa2;
          line-height: 32px;
          margin-top: 8px;
        }
        .section-btn {
          margin-top: 38px;
          width: 434px;
          height: 44px;
          cursor: pointer;
          background: linear-gradient(
            132deg,
            #ff4747 0%,
            #b500ff 52%,
            #1c42ff 100%
          );
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-family: DINPro-Bold, DINPro;
          color: #ffffff;
          line-height: 32px;
        }
      }
    }
    .section-levels {
      margin-top: -120px;
      .section-levels-hd {
        padding-top: 120px;
        padding-bottom: 24px;
        z-index: 99;
        position: sticky;
        top: 0px;
        background: #101013;
        transition: all .35s;
        .section-levels-hd-center {
          width: 1150px;
          margin: auto;
          justify-content: space-between;
        }
        .section-levels-hd-title {
          font-size: 20px;
          font-family: DINPro-Bold;
          color: #778aa2;
          line-height: 32px;
          text-transform: uppercase;
          span {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 3px;
            background: #778aa2;
            margin-right: 12px;
          }
        }
        .section-levels-hd-l {
          p {
            &:nth-child(2) {
              font-size: 20px;
              font-weight: 400;
              color: #778aa2;
              line-height: 32px;
              margin-top: 14px;
              text-align: left;
            }
            &:nth-child(3) {
              margin-top: 14px;
              span {
                font-size: 20px;
                line-height: 32px;
                font-family: DINPro-Regular, DINPro;
                font-weight: 400;
                margin-right: 30px;
                span {
                  margin-top: 10px;
                  margin-right: 4px;
                  width: 16px;
                  height: 16px;
                  border-radius: 8px;
                }
                &:nth-child(1) {
                  color: #a7e3fd;
                  span {
                    background: #a7e3fd;
                  }
                }
                &:nth-child(2) {
                  color: #f4d762;
                  span {
                    background: #f4d762;
                  }
                }
                &:nth-child(3) {
                  color: #e6e6e6;
                  span {
                    background: #e6e6e6;
                  }
                }
                &:nth-child(4) {
                  color: #fa9d76;
                  span {
                    background: #fa9d76;
                  }
                }
              }
            }
          }
        }
        .section-levels-hd-r {
          .section-levels-hd-r-item {
            margin-top: 14px;
            p {
              font-size: 20px;
              font-weight: 400;
              color: #778aa2;
              line-height: 32px;
            }
            .section-levels-hd-r-p {
              margin-left: 21px;
              width: 274px;
              height: 3px;
              background: rgba($color: #778aa2, $alpha: 0.5);
              border-radius: 2px;
              position: relative;
              .section-levels-hd-r-p-a {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background: #778aa2;
                border-radius: 2px;
                width: 0;
                transition: all .75s;
              }
            }
          }
        }
      }
      .section-main {
        width: 1150px;
        margin: auto;
        margin-top: 50px;
        text-align: left;
        justify-content: space-between;
        .section-main-l {
          margin-top: -100px;
        }
        .section-item {
          height: 619px;
          display: flex;
          align-items: center;
          margin-bottom: 100px;
          &:last-child {
            // margin-bottom: 0;
          }
          &.section-item-sticky {
            position: sticky;
            top: 317px;
            z-index: 19;
            .section-item-r {
              position: relative;
              z-index: 9;
            }
            .bg-3 {
              position: absolute;
              right: -397px;
              width: 1920px;
              z-index: 0;
              height: auto;
              top: -468px;
            }
          }
          .section-item-l {
            position: relative;
            z-index: 20;
            // margin-top: -200px;
            position: sticky;
            top: 442px;
            .section-item-title {
              text-align: left;
              font-size: 32px;
              font-family: DINPro-Bold, DINPro;
              color: #778aa2;
              line-height: 32px;
              display: inline-block;
              &.section-item-title-1 {
                background: linear-gradient(295deg, #f09256 0%, #cbffec 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              &.section-item-title-2 {
                background: linear-gradient(295deg, #ffffff 0%, #cbffec 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              &.section-item-title-3 {
                background: linear-gradient(295deg, #f2ce2b 0%, #cbffec 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              &.section-item-title-4 {
                background: linear-gradient(295deg, #0495ff 0%, #cbffec 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
            .section-item-l-div {
              margin-top: 48px;
              & ~ .section-item-l-div {
                margin-top: 24px;
              }
              p {
                font-size: 20px;
                color: #778aa2;
                line-height: 32px;
                span {
                  font-family: DINPro-Bold;
                }
              }
              .section-item-p {
                margin-left: 12px;
                width: 241px;
                height: 3px;
                background: rgba($color: #778aa2, $alpha: 0.5);
                border-radius: 2px;
                position: relative;
                .section-item-p-a {
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  height: 3px;
                  border-radius: 2px;
                  width: 0;
                  transition: all .75s;
                  &.section-item-p-a-1 {
                    background: linear-gradient(
                      295deg,
                      #f09256 0%,
                      #cbffec 100%
                    );
                  }
                  &.section-item-p-a-2 {
                    background: linear-gradient(
                      295deg,
                      #ffffff 0%,
                      #cbffec 100%
                    );
                  }
                  &.section-item-p-a-3 {
                    background: linear-gradient(295deg, #F2CE2B 0%, #CBFFEC 100%);
                  }
                  &.section-item-p-a-4 {
                    background: linear-gradient(295deg, #0C98FF 0%, #CBFFEC 100%);
                  }
                }
              }
              .section-item-l-div-p {
                margin-left: 31px;
                img {
                  width: 31px;
                  height: 31px;
                }
                p {
                  margin-left: 10px;
                  font-size: 20px;
                  color: #778aa2;
                  line-height: 32px;
                  background: linear-gradient(
                    295deg,
                    #1c42ff 0%,
                    #4be9f2 50%,
                    #cbffec 100%
                  );
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  span {
                    font-family: DINPro-Bold, DINPro;
                  }
                }
              }
            }
            .section-item-l-img {
              margin-top: 22px;
              width: 421px;
              height: auto;
            }
          }
          .section-item-r {
            width: 563px;
            height: 419px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -200px;
            img {
              height: auto;
              transform: scale(1.05);
              transition: all .3s;
            }
          }
        }
      }
    }
  }
}
@keyframes myRotate {
  0% {
    transform: rotate(90deg) scale(0);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes myRotate1 {
  0% {
    transform: rotate(360deg) scale(1);
  }
  2.5% {
    transform: rotate(315deg) scale(0.85);
  }
  5% {
    transform: rotate(270deg) scale(1);
  }
  25% {
    transform: rotate(270deg) scale(1);
  }
  27.5% {
    transform: rotate(225deg) scale(0.85);
  }
  30% {
    transform: rotate(180deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  52.5% {
    transform: rotate(135deg) scale(0.85);
  }
  55% {
    transform: rotate(90deg) scale(1);
  }
  75% {
    transform: rotate(90deg) scale(1);
  }
  77.5% {
    transform: rotate(45deg) scale(0.85);
  }
  80% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes float1 {
  0% {
    transform: translateY(0px) scale(1);
  }
  66% {
    transform: translateY(-10px) scale(1.01);
  }
  100% {
    transform: translateY(-10px) scale(1.05);
  }
}

@keyframes float2 {
  0% {
    transform: translateY(0px) scale(1);
  }
  25% {
    transform: translateY(10px) scale(1);
  }
  50% {
    transform: translateY(0px) scale(1);
  }
  75% {
    transform: translateY(-10px) scale(1);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
}

@keyframes float3 {
  0% {
    transform: translateY(0px) translateX(0);
  }
  50% {
    transform: translateY(10px) translateX(-10px);
  }
  0% {
    transform: translateY(0px) translateX(0);
  }
}
</style>