<template>
  <div class="nav">
    <div class="nav-content flex-center-sb">
      <div class="logo flex-center">
        <a href="/" class="flex-center"><img src="@/assets/images/nav_logo.png" alt=""></a>
      </div>
      <div class="menu flex-center">
        <!-- <div class="menu-item"><router-link to="/ambassador">Ambassador <span>NEW</span></router-link></div> -->
        <div class="connect-btn-w" v-if="address">
          <div class="connect-btn flex-center-center menu-item menu-owned" >My Owned</div>
          <div class="popconfirm flex-center-center">
            <img src="@/assets/images/popconfirm.svg" alt="">
            <div class="popconfirm-content">
              <div class="popconfirm-title"><span>My Owned</span></div>
              <div v-if="!balanceOf && network" class="popconfirm-owned flex-center-center">
                <p v-if="contractChainId != network.chainId" class="switch" @click="switchChain">Switch Mainnet</p>
                <p v-else>no items</p>
              </div>
              <div v-if="balanceOf" class="popconfirm-owned flex-center-center">
                <div class="owned-r flex-center">
                  <img src="@/assets/images/mystery_box.png" alt="">
                  <p>X <span>{{balanceOf}}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="connect-btn-w">
          <div class="connect-btn flex-center-center menu-item" @click="connectWallet" >{{formatAddress || 'Connect Wallet'}}</div>
          <div class="dropdown flex-center-center" v-if="address" @click="logOut">
            <p>Log out</p>
            <img src="@/assets/images/log_out.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useContract } from '../hooks/useContract'
import { walletSwitchChain } from '../libs/walletSwitchChain'

export default {
  setup() {
    const store = useStore()
    const formatAddress = ref('')
    const {
      setContract
    } = useContract()
    const balanceOf = computed(() => {
      return store.state.balanceOf
    })
    const web3 = computed(() => {
      return store.state.web3
    })
    const address = computed(() => {
      return store.state.address
    })
    const network = computed(() => {
      return store.state.network
    })
    const contractChainId = computed(() => {
      return store.state.contractChainId
    })
    const switchChain = () => {
      walletSwitchChain(contractChainId.value).then(res => {
        console.log(res)
      })
    }
    watch(address, () => {
      if (address.value) {
        formatAddress.value = `${address.value.slice(0, 6)}...${address.value.slice(-6)}`
      } else {
        formatAddress.value = ''
      }
    }, { immediate: true })
    watch(web3, async (value) => {
      if (value) {
        setContract(value)
      }
    }, {immediate: true})
    const connectWallet = () => {
      if (!web3.value) {
        store.commit("getWeb3")
      }
    }
    const logOut = () => {
      store.commit("init")
    }
    return {
      web3,
      balanceOf,
      network,
      formatAddress,
      address,
      contractChainId,
      connectWallet,
      logOut,
      switchChain
    }
  }
}
</script>
<style lang="scss" scoped>
.nav {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba($color: #171619, $alpha: 0.85);
  z-index: 999;
  .nav-content {
    width: 1280px;
    margin: auto;
    height: 80px;
    .logo {
      img {
        width: 323px;
        height: auto;
      }
    }
    .menu-item {
      font-size: 18px;
      font-family: DINPro-Bold;
      color: #FFFFFF;
      line-height: 32px;
      cursor: pointer;
      position: relative;
      span {
        position: absolute;
        top: -02px;
        right: -22px;
        font-size: 10px;
        font-family: DINPro-Bold;
        color: #4BE9F2;
        line-height: 12px;
      }
    }
    .connect-btn {
      width: 162px;
      height: 38px;
      background: #1C42FF;
      border-radius: 19px;
      position: relative;
      z-index: 1;
      &.menu-owned {
        background: linear-gradient(132deg, #FF4747 5%, #B500FF 52%, #1C42FF 100%);
      }
    }
    .connect-btn-w {
      position: relative;
      border-radius: 19px;
      margin-left: 29px;
      width: 162px;
      height: 38px;
      box-sizing: border-box;

      &:hover {
        .dropdown {
          display: flex;
        }
        .popconfirm {
          display: block;
        }
      }
    }
    .popconfirm {
      display: none;
      position: absolute;
      right: 0;
      padding-top: 8px;
      top: 38px;
      .popconfirm-content {
        position: absolute;
        left: 0;
        top: 25px;
        bottom: 0;
        right: 0;
        padding: 20px 16px;
        box-sizing: border-box;
        font-size: 0;
        .popconfirm-title {
          text-align: center;
          span {
            font-size: 24px;
            font-family: DINPro-Bold, DINPro;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 31px;
            background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-transform: uppercase;
          }
        }
        .popconfirm-owned {
          margin-top: 21px;
          height: 130px;
          background: #101013;
          border-radius: 9px;
          border: 1px solid #27262A;
          padding: 11px 36px;
          box-sizing: border-box;
          p {
            font-size: 20px;
            font-weight: 400;
            color: #778AA2;
            line-height: 32px;
            text-align: center;
            opacity: .35;
            font-family: DINPro-Bold;
            text-transform: uppercase;
            &.switch {
              text-decoration: underline;
              cursor: pointer;
              color: #1C42FF;
              opacity: 1;
            }
          }
          .owned-l {
            font-size: 20px;
            font-family: DINPro-Bold, DINPro;
            color: #778AA2;
            line-height: 32px;
          }
          .owned-r {
            img {
              width: 175px;
              height: auto;
              margin-right: 15px;
            }
            p {
              font-size: 24px;
              font-family: DINPro-Regular, DINPro;
              color: #778AA2;
              line-height: 31px;
              opacity: 1;
              span {
                font-family: 'DINPro-Bold';
              }
            }
          }
        }
      }
    }
    .dropdown {
      margin: auto;
      font-size: 18px;
      color: #FF3333;
      line-height: 32px;
      width: 162px;
      cursor: pointer;
      border-radius: 19px;
      font-family: DINPro-Bold;
      background: #101013;
      padding-bottom: 13px;
      position: relative;
      top: -38px;
      padding-top: 43px;
      display: none;
      p {
        font-family: DINPro-Bold;
      }
      img {
        width: 22px;
        height: auto;
        margin-left: 18px;
      }
    }
  }
}

</style>