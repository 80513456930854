<template>
  <div>
    <div v-if="!isMobile" class="home">
      <Header @showMint="showMint" :alwaysShow="alwaysShow" />
      <Main @mint="clickMint" />
      <FAQ />
      <Footer />
      <Mint v-if="isShowMint" @closeMint="isShowMint = false" />
      <div class="bg">
        <img src="@/assets/images/bg1.png" alt="" class="bg-1" />
        <img src="@/assets/images/bg2.png" alt="" class="bg-2" />
        <!-- <img src="@/assets/images/bg3.png" alt="" class="bg-3" /> -->
      </div>
    </div>
    <div class="home-m" v-if="isMobile">
      <MHeader @showMint="showMint" />
      <MMain @mint="clickMint" />
      <MFAQ />
      <MFooter />
      <MMint v-if="isShowMint" @closeMint="isShowMint = false" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/index/Header.vue";
import MHeader from "@/components/mIndex/Header.vue";
import MMain from "@/components/mIndex/Main.vue";
import Main from "@/components/index/Main.vue";
import Footer from "@/components/index/Footer.vue";
import FAQ from "@/components/index/FAQ.vue";
import Mint from "@/components/Mint.vue";
import MMint from "@/components/MMint.vue";
import MFAQ from "@/components/mIndex/FAQ.vue";
import MFooter from "@/components/mIndex/Footer.vue";
import { computed, ref, toRaw, watch} from 'vue'
import { useStore } from 'vuex'
import { walletSwitchChain } from '../libs/walletSwitchChain'

export default {
  name: "Home",
  components: {
    Header,
    MHeader,
    MMain,
    Main,
    Footer,
    FAQ,
    Mint,
    MFAQ,
    MFooter,
    MMint
  },
  setup() {
    let doShowMint = false
    const store = useStore()
    const isShowMint = ref(false)
    const alwaysShow = ref(false)
    const isMobile = computed(() => {
      return store.state.isMobile
    })
    const web3 = computed(() => {
      return store.state.web3
    })
    const network = computed(() => {
      return store.state.network
    })
    const contractChainId = computed(() => {
      return store.state.contractChainId
    })
    const showMint = () => {
      if (!toRaw(web3.value)) {
        doShowMint = true
        store.commit("getWeb3")
      } else if (network.value.chainId != contractChainId.value) {
        doShowMint = true
        switchChain()
      } else {
        isShowMint.value = true
        alwaysShow.value = false
      }
    }
    const switchChain = () => {
      walletSwitchChain(contractChainId.value).then(res => {
        console.log(res)
      })
    }
    const clickMint = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      alwaysShow.value = true
    }
    watch(web3, (value) => {
      if (value && doShowMint) {
        showMint()
      }
    })
    return {
      isMobile,
      alwaysShow,
      isShowMint,
      clickMint,
      showMint
    }
  },
};
</script>
<style lang="scss" scoped>
.home-m {
  width: 100vw;
  min-height: 100vh;
  -webkit-tap-highlight-color: transparent;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  img {
    width: 1920px;
    height: auto;
    object-fit: cover;
    &:nth-child(2) {
      margin-top: 284px;
    }
    // &:nth-child(3) {
    //   margin-top: 518px;
    // }
  }
}
</style>
<style lang="scss">
body {
  position: relative;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden !important;
}
img {
  user-select: none;
  pointer-events: none;
}
</style>

