<template>
  <div class="header">
    <div class="header-content flex-center">
      <div class="header-l">
        <img src="@/assets/images/logo_1.png" alt="">
        <p>A collection of utility NFTs as the Pass of Spola Ecosystem</p>
      </div>
      <div class="header-r">
        <img src="@/assets/images/light.png" alt="" class="light">
        <img src="@/assets/images/mystery_box.png" alt="" class="pass-box">
        <img src="@/assets/images/mystery_box_w.png" alt="" :class="['pass-box-w', alwaysShow ? 'pass-box-w-show' : '']">
        <div :class="['header-r-section', alwaysShow ? 'header-r-section-show' : '']">
          <p>spola pass<br />mystery box</p>
          <div class="btn flex-center-center" @click="showMint">MINT NOW <img src="@/assets/images/icon_4.svg" alt=""></div>
        </div>
      </div>
    </div>
    <div class="height-progress">
      <div class="height-progress-line">
        <div class="height-progress-default flex-center">
          <div class="height-progress-default-item"></div>
          <div class="height-progress-default-item"></div>
          <div class="height-progress-default-item"></div>
        </div>
        <div class="height-progress-activate"></div>
        <div class="height-line-item height-line-1">
          <p><span><img src="@/assets/images/hot_icon.svg" alt="">Mint round 1</span><br /><span>Nov 5, 8:00 AM (UTC+0)</span></p>
        </div>
        <div class="height-line-item height-line-2">
          <p><span>Mint round 2</span><br /><span>Nov 8, 8:00 AM (UTC+0)</span></p>
        </div>
        <div class="height-line-item height-line-4">
          <p><span>Mint close!</span><br /><span>Nov 10, 8:00 AM  (UTC+0)</span></p>
          <div v-if="false" class="icon flex-center-sb">
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
        <div class="height-line-item height-line-3">
          <p><span>Reveal & Redemption</span><br /><span>Nov 10, 9:00 AM (UTC+0)</span></p>
        </div>
        <div class="height-line-item height-line-5">
          <p><span>Redemption close</span><br /><span>Nov 13, 9:00 AM (UTC+0)</span></p>
          <div class="icon flex-center-sb">
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ['alwaysShow'],
  setup(props, { emit }) {
    const showMint = () => {
      emit('showMint')
    }
    return { showMint }
  },
})
</script>
<style lang="scss" scoped>
.header {
  padding-top: 80px;
  width: 890px;
  margin: auto;
  position: relative;
  z-index: 9;
  .header-content {
    margin-top: 50px;
    .header-l {
      width: 342px;
      flex: 0 0 342px;
      margin-right: 24px;
      img {
        width: 284px;
        height: auto;
      }
      p {
        margin-top: 29px;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        background: linear-gradient(299deg, #1C42FF 0%, #4BE9F2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .header-r {
      width: 665px;
      height: 490px;
      flex: 0 0 665px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .light {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        animation: myRotate1 2s linear infinite;
      }
      .pass-box-w {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 520px;
        height: 274px;
        animation: shake 2s linear infinite;
        z-index: 0;
        opacity: .2;
        transform: rotate(-12deg);
        &.pass-box-w-show {
          z-index: 2;
        }
      }
      .pass-box {
        width: 520px;
        height: 274px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        animation: shake 2s linear infinite;
        transform: rotate(-12deg)
      }
      .header-r-section {
        position: relative;
        z-index: 0;
        opacity: 0;
        transition: all .3s;
        &.header-r-section-show {
          z-index: 9;
          opacity: 1;
        }
        p {
          font-size: 32px;
          font-family: DINPro-Bold, DINPro;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 41px;
          background: linear-gradient(295deg, #1C42FF 0%, #4BE9F2 50%, #CBFFEC 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
          text-transform: uppercase;
        }
        .btn {
          font-size: 16px;
          font-family: DINPro-Bold, DINPro;
          color: #FFFFFF;
          line-height: 32px;
          width: 144px;
          height: 38px;
          background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
          border-radius: 19px;
          margin: auto;
          margin-top: 31px;
          cursor: pointer;
          img {
            width: 9px;
            height: auto;
            margin-left: 7px;
            position: relative;
            left: 0;
            transition: all .3s;
          }
          &:hover {
            img {
              left: 5px
            }
          }
        }
      }
      &:hover {
        .pass-box-w {
          z-index: 2;
        }
        .header-r-section {
          z-index: 9;
          opacity: 1;
        }
      }
    }
  }
  .height-progress {
    margin: auto;
    margin-top: 71px;
    width: 100%;
    height: 2px;
    box-sizing: border-box;
    .height-progress-line {
      height: 2px;
      // background: rgba($color: #778AA2, $alpha: .2);
      border-radius: 1px;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 0;
      .height-progress-default {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        .height-progress-default-item {
          height: 2px;
          background: rgba($color: #778AA2, $alpha: .2);
          border-radius: 2px;
          &:nth-child(1) {
            width: 380px;
          }
          &:nth-child(2) {
            margin-left: 29px;
            width: 388px;
          }
          &:nth-child(3) {
            margin-left: 29px;
            flex: 1;
          }
        }
      }
      .height-progress-activate {
        position: absolute;
        left: 0;
        // right: 0;
        top: 0;
        bottom: 0;
        width: 598px;
        border-radius: 2px;
        z-index: 2;
        background: linear-gradient(132deg, rgba(28, 66, 255, 1), rgba(181, 0, 255, 1), rgba(255, 71, 71, 1));
        &::after {
          content: '';
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background: rgba(255, 71, 71, 1);
          position: absolute;
          right: -8px;
          top: -7px;
        }
      }
      .height-line-item {
        width: 62px;
        height: 2px;
        position: relative;
        img {
          width: 20px;
          height: auto;
          margin-right: 5px;
        }
        p {
          position: absolute;
          top: -64px;
          font-size: 0;
          text-align: center;
          span {
            font-size: 16px;
            font-family: DINPro-Bold, DINPro;
            color: #FFFFFF;
            line-height: 32px;
            white-space: nowrap;
            &:last-child {
              font-size: 12px;
              font-family: DINPro-Regular, DINPro;
              font-weight: 400;
              color: #778AA2;
              line-height: 16px;
              white-space: nowrap;
              margin-top: 6px;
            }
          }
        }
        &::after {
          content: '';
          width: 08px;
          height: 08px;
          border-radius: 04px;
          background: rgba(255, 255, 255, 1);
          position: absolute;
          right: -04px;
          top: -03px;
        }
      }
      .height-line-1 {
        p {
          right: -64px;
          span {
            color: #FF4747;
          }
        }
        &::after {
          display: none;
        }
      }
      .height-line-2 {
        width: 168px;
        p {
          right: -64px;
        }
        &::after {
          display: none;
        }
      }

      .height-line-3 {
        width: 200px;
        p {
          right: -79px;
        }
      }
      .height-line-4 {
        width: 168px;
        p {
          right: -67px;
        }
        .icon {
          width: 13px;
          height: 12px;
          position: absolute;
          right: -10px;
          top: -5px;
          // background: #101013;
          padding: 0 7px;
          i {
            display: inline-block;
            background: #FF4747;
            border-radius: 2px;
            height: 12px;
            width: 2px;
          }
        }
        &::after {
          display: none;
        }
      }
      .height-line-5 {
        width: 216px;
        p {
          right: -67px
        }
        .icon {
          width: 13px;
          height: 12px;
          position: absolute;
          right: -10px;
          top: -5px;
          // background: #101013;
          padding: 0 7px;
          i {
            display: inline-block;
            background: #FF4747;
            border-radius: 2px;
            height: 12px;
            width: 2px;
          }
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
@keyframes myRotate1{
  0%{ transform: scale(1);}
  50%{ transform: scale(1.1);}
  100%{ transform: scale(1);}
}
@keyframes shake {
  70%, 80% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(-19deg);
  }
  65%,
  85% {
    transform: rotate(-12deg);
  }
}
</style>