<template>
  <div>
    <div class="nav flex-center-sb">
      <img src="@/assets/images/logo_m.png" alt="" class="logo">
      <div class="wallet-icon flex-center" @click="isShowWallet = true">
        <img src="@/assets/images/wallet.svg" alt="">
        <span :style="{opacity: address ? '1' : '0'}"></span>
      </div>
    </div>
    <div class="wallet" :style="{'z-index': isShowWallet? '999' : '-99'}" @touchmove.prevent @mousewheel.prevent @click="hiddenWallet">
      <div class="wallet-content" :style="{'right': isShowWallet? '0' : '-6rem'}" @click.stop>
        <div class="close" @click="hiddenWallet">
          <img src="@/assets/images/close.svg" alt="">
        </div>
        <div v-if="!address" class="connect-btn flex-center-center" @click="connectWallet">Connect Wallet</div>
        <div v-else class="address flex-center" @click="isShowLogOut = !isShowLogOut"><span></span><p>{{formatAddress}}</p>
          <svg :style="{transform: isShowLogOut ? 'rotate(-90deg)' : 'rotate(90deg)'}" width="7px" height="11px" viewBox="0 0 7 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="SPOLA-PASS-NEW" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g id="Spola-PASS-NEW" transform="translate(-625.000000, -1483.000000)" stroke="#FFFFFF" stroke-width="2">
                <g id="编组-30" transform="translate(516.133002, 1470.671127)">
                  <polyline id="路径-3备份-2" transform="translate(110.357004, 17.994059) rotate(45.000000) translate(-110.357004, -17.994059) " points="107.407004 15.0440585 113.307004 15.0440585 113.307004 20.9440585"></polyline>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div v-if="address" class="log-out flex-center-center" @click="logOut" :style="{height: isShowLogOut ? '1.04rem' : 0, 'border-bottom-width': isShowLogOut ? '1px' : '0'}">
          <p>Log out</p>
          <img src="@/assets/images/log_out.svg" alt="">
        </div>
        <div class="wallet-list-w">
          <div class="wallet-list">
            <div class="wallet-title">My Owned</div>
            <div class="wallet-list-content flex-center-center">
              <div v-if="!balanceOf && network" class="owned flex-center-center">
                <p v-if="contractChainId != network.chainId" class="switch" @click="switchChain">Switch Mainnet</p>
                <p v-else>no items</p>
              </div>
              <div v-if="balanceOf" class="owned flex-center-center flex-column">
                <img src="@/assets/images/mystery_box.png" alt="">
                <p class="balance-of">x <span>{{balanceOf}}</span></p>
              </div>
            </div>
            <div v-if="false" class="wallet-list-content flex-center-sb">
              <div class="wallet-list-item">
                <div class="item-card">
                  <img src="@/assets/images/wallet_card1.png" alt="">
                </div>
                <div class="item-card-num">x 0</div>
              </div>
              <div class="wallet-list-item">
                <div class="item-card">
                  <img src="@/assets/images/wallet_card2.png" alt="">
                </div>
                <div class="item-card-num">x 0</div>
              </div>
              <div class="wallet-list-item">
                <div class="item-card">
                  <img src="@/assets/images/wallet_card3.png" alt="">
                </div>
                <div class="item-card-num">x 0</div>
              </div>
              <div class="wallet-list-item">
                <div class="item-card">
                  <img src="@/assets/images/wallet_card4.png" alt="">
                </div>
                <div class="item-card-num">x 0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useContract } from '../hooks/useContract'
import { walletSwitchChain } from '../libs/walletSwitchChain'
export default {
  setup() {
    const store = useStore()
    const {
      setContract
    } = useContract()
    const formatAddress = ref('')
    const isShowLogOut = ref(false)
    const isShowWallet = ref(false)
    const web3 = computed(() => {
      return store.state.web3
    })
    const network = computed(() => {
      return store.state.network
    })
    const address = computed(() => {
      return store.state.address || ''
    })
    const balanceOf = computed(() => {
      return store.state.balanceOf
    })
    const contractChainId = computed(() => {
      return store.state.contractChainId
    })
    const switchChain = () => {
      walletSwitchChain(contractChainId.value).then(res => {
        console.log(res)
      })
    }
    watch(address, () => {
      if (address.value) {
        formatAddress.value = `${address.value.slice(0, 6)}...${address.value.slice(-6)}`
      } else {
        formatAddress.value = ''
      }
    }, { immediate: true })
    watch(web3, async (value) => {
      if (value) {
        setContract(value)
      }
    }, {immediate: true})
    const connectWallet = () => {
      store.commit("getWeb3")
    }
    const logOut = () => {
      store.commit("init")
      hiddenWallet()
    }
    const hiddenWallet = () => {
      isShowWallet.value = false
      isShowLogOut.value = false
    }
    return {
      web3,
      network,
      formatAddress,
      address,
      isShowLogOut,
      isShowWallet,
      balanceOf,
      contractChainId,
      connectWallet,
      logOut,
      hiddenWallet,
      switchChain
    }
  }
}
</script>
<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: .96rem;
  width: 100%;
  background: rgba(23, 22, 25, 0.85);
  backdrop-filter: blur(10px);
  padding: 0 .45rem;
  box-sizing: border-box;
  z-index: 999;
  .logo {
    width: 3.37rem;
    height: auto;
  }
  .wallet-icon {
    img {
      width: .6rem;
      height: auto;
    }
    span {
      margin-left: .09rem;
      width: .12rem;
      height: .12rem;
      border-radius: .12rem;
      background: #69EFAF;
    }
  }
}
.wallet {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;
  transition: all .3s;
  background: rgba($color: #000, $alpha: .8);
  .wallet-content {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5.5rem;
    background: #101013;
    padding: 1.96rem .43rem 0;
    box-sizing: border-box;
    transition: all .3s;
    .close {
      font-size: 0;
      position: absolute;
      right: .51rem;
      top: .37rem;
      width: .39rem;
      img {
        width: .39rem;
        height: auto;
      }
    }
    .connect-btn {
      width: 100%;
      height: .66rem;
      background: #1C42FF;
      border-radius: .33rem;
      font-size: .32rem;
      font-family: DINPro-Bold, DINPro;
      color: #FFFFFF;
      margin-bottom: .45rem;
    }
    .address {
      width: 100%;
      height: .66rem;
      font-size: .32rem;
      color: #FFFFFF;
      line-height: .41rem;
      padding-bottom: .15rem;
      border-bottom: 1px solid rgba(255, 255, 255, .1);
      margin-bottom: .29rem;
      span {
        width: .12rem;
        height: .12rem;
        margin-top: .02rem;
        border-radius: .06rem;
        background: #69EFAF;
        margin-right: .16rem;
      }
      p {
        flex: 1;
        font-family: DINPro-Bold, DINPro;
      }
      svg {
        transition: all .3s;
        width: .16rem;
        height: auto;
        transform: rotate(90deg);
        margin-top: .04rem;
      }
    }
    .log-out {
      margin-top: -.29rem;
      height: 1.04rem;
      border-bottom: 1px solid rgba(255, 255, 255, .1);
      margin-bottom: .35rem;
      transition: all .3s;
      box-sizing: border-box;
      overflow: hidden;
      p {
        font-size: .32rem;
        font-family: DINPro-Bold, DINPro;
        color: #FF4747;
        line-height: .41rem;
        margin-right: .16rem;
      }
      img {
        width: .4rem;
        height: auto;
        margin-top: .04rem;
      }
    }
    .wallet-list-w {
      width: 100%;
      // height: 6.36rem;
      border-radius: .16rem;
      border: .01rem solid;
      position: relative;
      .wallet-list {
        padding: .14rem .23rem .25rem;
        border-radius: .16rem;
        background: #101013;
        // height: 6.36rem;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        .wallet-title {
          font-size: .32rem;
          font-family: DINPro-Bold, DINPro;
          color: #FFFFFF;
          line-height: .41rem;
          background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .wallet-list-content {
          margin-top: .2rem;
          width: 100%;
          background: #171619;
          border-radius: .10rem;
          border: .01rem solid #27262A;
          padding: .55rem .16rem 0;
          box-sizing: border-box;
          flex-wrap: wrap;
          .owned {
            width: 100%;
            padding-bottom: .55rem;
            p {
              font-size: .32rem;
              color: #778AA2;
              line-height: .41rem;
              text-transform: uppercase;
              font-family: DINPro-bold;
              &.switch {
                font-family: DINPro-bold;
                color: #1C42FF;
                text-decoration: underline;
              }
              &.balance-of {
                position: relative;
                top: .21rem;
                font-family: DINPro-Regular;
                text-transform: none;
                span {
                  font-family: DINPro-bold;
                }
              }
            }
            img {
              width: 3.69rem;
              height: auto;
            }
          }
          .wallet-list-item {
            width: 1.8rem;
            margin-bottom: .61rem;
            .item-card {
              img {
                width: 1.8rem;
                height: auto;
              }
            }
            .item-card-num {
              text-align: center;
              margin-top: .01rem;
              font-size: .24rem;
              font-family: DINPro-Regular, DINPro;
              font-weight: 400;
              color: #778AA2;
              line-height: .31rem;
            }
          }
        }
      }
      &::after {
        content: '';
        position: absolute;
        left: -.01rem;
        right: -.01rem;
        top: -.01rem;
        bottom: -.01rem;
        background: linear-gradient(132deg, rgba(255, 71, 71, 1), rgba(181, 0, 255, 1), rgba(28, 66, 255, 1));
        border-radius: .16rem;
        z-index: 0;
      }
    }
  }

}
</style>