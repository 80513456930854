import { createStore } from 'vuex'
// import { ethers } from 'ethers'
import { connectWallet } from '../libs/connectWallet'
export default createStore({
  state: {
    web3: null,
    address: "",
    balanceOf: 0,
    network: null,
    isMobile: false,
    contractChainId: 1
  },
  mutations: {
    async getWeb3(state, init) {
      let web3 = await connectWallet(init)
      let accounts = await web3.listAccounts()
      let wallet_address = ''
      if (accounts.length) {
        wallet_address = accounts[0]
      }
      let network = await web3.getNetwork()

      state.web3 = web3
      state.address = wallet_address
      state.network = network
      console.log(state)
    },
    setIsMobile(state, value) {
      state.isMobile = value
    },
    setBalanceOf(state, value) {
      state.balanceOf = value
    },
    init(state) {
      state.web3 = null
      state.address = ""
      state.network = null
    }
  },
  actions: {
  },
  modules: {
  }
})
