<template>
  <div class="header">
    <div class="header-content flex-center-center">
      <div class="header-l">
        <img src="@/assets/images/logo_1.png" alt="">
        <p>A collection of utility NFTs as the Pass of <br /> Spola Ecosystem</p>
      </div>
      <div class="header-r">
        <img src="@/assets/images/m_bg1.png" alt="" class="bg">
        <p class="">spola pass mystery box</p>
        <div class="header-r-c">
          <img src="@/assets/images/light.png" alt="" class="light">
          <img src="@/assets/images/m_mystery_box.png" alt="" class="pass-box">
        </div>

        <div class="btn flex-center-center" @click="showMint">MINT NOW <img src="@/assets/images/icon_4.svg" alt=""></div>
      </div>
    </div>
    <p class="hint">*ALL TIMEZONE IS UTC+0</p>
    <div class="height-progress">
      <div class="height-progress-line flex-center">
        <div class="height-progress-activate"></div>
        <div class="height-line-item height-line-1">
          <p><span class="flex-center-center"><img src="@/assets/images/hot_icon.svg" alt="">Mint round 1</span><br /><span>Nov 5, 8:00 AM</span></p>
        </div>
        <div class="height-line-item height-line-2">
          <p><span>Mint round 2</span><br /><span>Nov 8, 8:00 AM</span></p>
        </div>
        <div class="height-line-item height-line-3">
          <p><span>Mint close!</span><br /><span>Nov 10, 8:00 AM</span></p>
          <div class="icon flex-center-sb">
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </div>
      <div class="height-progress-line flex-center">
        <div class="height-progress-activate height-progress-activate-1"></div>
        <div class="height-line-item height-line-4">
          <p><span>Reveal & Redemption</span><br /><span>Nov 10, 9:00 AM</span></p>
        </div>
        <div class="height-line-item height-line-5">
          <p><span>Redemption close</span><br /><span>Nov 13, 9:00 AM</span></p>
          <div class="icon flex-center-sb">
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    showMint() {
      this.$emit('showMint')
    }
  }
})
</script>
<style lang="scss" scoped>
.header {
  padding-top: 1.04rem;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 9;
  .header-content {
    flex-direction: column;
    .header-l {
      // width: 2.84rem;
      text-align: center;
      img {
        width: 2.84rem;
        height: auto;
      }
      p {
        margin-top: .27rem;
        font-size: .24rem;
        color: #FFFFFF;
        line-height: .32rem;
        background: linear-gradient(299deg, #1C42FF 0%, #4BE9F2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .header-r {
      width: 100%;
      margin-top: .92rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .bg {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
      }
      .header-r-c {
        width: 6.56rem;
        height: 4.90rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -.16rem;
      }
      .light {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        animation: myRotate1 2s linear infinite;
      }
      .pass-box-w {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 5rem;
        height: auto;
        animation: shake 2s linear infinite;
        z-index: 0;
        opacity: .2;
        transform: rotate(7deg);
        &.pass-box-w-show {
          z-index: 2;
        }
      }
      .pass-box {
        width: 4.98rem;
        height: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        animation: shake 2s linear infinite;
        transform: rotate(0deg)
      }
      p {
        font-size: .32rem;
        font-family: DINPro-Bold;
        color: #FFFFFF;
        line-height: .41rem;
        letter-spacing: .02rem;
        background: linear-gradient(295deg, #1C42FF 0%, #4BE9F2 50%, #CBFFEC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
      }
      .btn {
        width: 4.06rem;
        height: .56rem;
        background: linear-gradient(132deg, #FF4747 10%, #B500FF 52%, #1C42FF 100%);
        border-radius: .16rem;
        margin-top: -.28rem;
        cursor: pointer;
        font-size: .24rem;
        font-family: DINPro-Bold,;
        color: #FFFFFF;
        position: relative;
        z-index: 9;
        img {
          width: .13rem;
          height: auto;
          margin-left: .14rem;
          position: relative;
          left: 0;
          animation: bounce-inSS 2s infinite;
        }
        @keyframes bounce-inSS {
          0%, 20%, 50%, 80%, 100% {
            transform: translateX(0);
          }
          20% {
            transform: translateX(.12rem);
          }
          60% {
            transform: translateX(.1rem);
          }
        }
      }
    }
  }
  .hint {
    font-size: .20rem;
    font-family: DINPro-Bold;
    color: #778AA2;
    line-height: .26rem;
    margin-top: .51rem;
    text-align: center;
  }
  .height-progress {
    padding: 0 .58rem 0 .47rem;
    margin: auto;
    margin-top: .42rem;
    width: 100%;
    box-sizing: border-box;
    .height-progress-line {
      margin-top: 1.12rem;
      height: .02rem;
      background: rgba($color: #778AA2, $alpha: .2);
      border-radius: 1px;
      position: relative;
      font-size: 0;
      .height-progress-activate {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        border-radius: .02rem;
        z-index: 2;
        background: linear-gradient(132deg, rgba(28, 66, 255, 1), rgba(181, 0, 255, 1), rgba(255, 71, 71, 1));
        &.height-progress-activate-1 {
          width: .94rem;
          &::after {
            content: '';
            width: .16rem;
            height: .16rem;
            border-radius: .08rem;
            background: rgba(255, 71, 71, 1);
            position: absolute;
            right: -.08rem;
            top: -.07rem;
          }
        }
      }
      .height-line-item {
        height: .02rem;
        position: relative;
        img {
          width: .2rem;
          height: auto;
          margin-right: .09rem;
        }
        p {
          position: absolute;
          top: -.7rem;
          font-size: 0;
          text-align: center;
          span {
            font-size: .24rem;
            font-family: DINPro-Bold;
            color: #FFFFFF;
            line-height: .31rem;
            white-space: nowrap;
            &:last-child {
              font-size: .20rem;
              font-family: DINPro-Regular;
              font-weight: 400;
              color: #778AA2;
              line-height: .26rem;
            }
          }
        }
        .icon {
          width: .13rem;
          height: .12rem;
          position: absolute;
          right: -.21rem;
          top: -.05rem;
          i {
            display: inline-block;
            background: #FF4747;
            border-radius: 2px;
            height: .12rem;
            width: .02rem;
          }
        }
        &::after {
          content: '';
          width: .08rem;
          height: .08rem;
          border-radius: .04rem;
          background: rgba(255, 255, 255, 1);
          position: absolute;
          right: -.04rem;
          top: -.03rem;
        }
      }
      .height-line-1 {
        width: .95rem;
        
        p {
          left: 0;
          text-align: left;
          span {
            color: #FF4747;
          }
        }
        &::after {
          display: none;
        }
      }
      .height-line-2 {
        width: 2.33rem;
        p {
          right: -.76rem;
          text-align: center;
        }
        &::after {
          display: none;
        }
      }

      .height-line-3 {
        flex: 1;
        p {
          right: -.23rem;
          text-align: right;
        }
        &::after {
          display: none;
        }
      }
      .height-line-4 {
        width: .94rem;
        p {
          text-align: left;
          left: 0;
        }
      }
      .height-line-5 {
        flex: 1;
        p {
          text-align: right;
          right: -.23rem;

        }
        &::after {
          display: none;
        }
      }
    }
  }
}
@keyframes myRotate1{
  0%{ transform: scale(1);}
  50%{ transform: scale(1.1);}
  100%{ transform: scale(1);}
}
@keyframes shake {
  70%, 80% {
    transform: rotate(-7deg);
  }
  75% {
    transform: rotate(7deg);
  }
  65%,
  85% {
    transform: rotate(0deg);
  }
}
</style>