<template>
  <div class="mint" >
    <div class="mint-content" >
      <div class="close flex-center-center" @click="close">CLOSE</div>
      <div class="title flex-center-center"><span>Spola PASS Mystery Box</span></div>
      <div class="sub-title">Mint Spola PASS now to get the premium IRL benefits including World Cup 2022 & $SPO and unlock massive privileges in Spola Labs.</div>
      <div class="progress">
        <div class="progress-line">
          <div 
            :class="['progress-activate', (nowTime > roundOneStartTime && nowTime < mintEndTime) ? 'progress-activate-after' : '']"
            :style="{width: (nowTime > roundOneStartTime && nowTime < roundTwoStartTime) ? '1.78rem' : (nowTime > roundTwoStartTime && nowTime < mintEndTime) ? '4.62rem' : (nowTime > mintEndTime && mintEndTime) ? '100%' : '1.4rem'}"
          ></div>
          <div class="line-item line-1">
            <p :class="[(nowTime > roundOneStartTime && nowTime < roundTwoStartTime) ? 'p-activate' : '']"><img src="@/assets/images/hot_icon.svg" alt=""><span>Round 1</span> - Nov 5, 8:00 AM  (UTC+0)</p>
          </div>
          <div class="line-item line-2">
            <p :class="[(nowTime > roundTwoStartTime && nowTime < mintEndTime) ? 'p-activate' : '']"><img src="@/assets/images/hot_icon.svg" alt=""><span>Round 2</span> - Nov 8, 8:00 AM  (UTC+0)</p>
          </div>
        </div>
        <div class="close-hint-wrap">
          <div class="icon flex-center-sb"><i></i><i></i><i></i></div>
          <div class="close-hint">
            <img src="@/assets/images/close_hint_m.svg" alt="">
            <p><span>Mint close!</span><br /><span>Nov 10, 8:00 AM (UTC+0)</span></p>
          </div>
        </div>
      </div>
      <div v-if="mintStatus == 'loading'" class="section section-loading flex-center-center">
        <div class="loading"><span class="loader"></span></div>
        <div class="loading-title">loading...</div>
      </div>
      <div v-if="mintStatus == 'succeed'" class="section section-success flex-center-center">
        <img src="@/assets/images/mint_success.png" alt="" class="bg-img">
        <div class="success-title">congratulations!</div>
        <div class="success-sub-title">You have successfully purchased <span>{{tokenQuantity}} Spola PASS Mystery Box</span></div>
        <div class="success-box">
          <img src="@/assets/images/mystery_box.png" alt="">
          <div class="success-num">x <span>{{tokenQuantity}}</span></div>
        </div>
      </div>
      <div v-if="mintStatus == 'init'" class="section">
        <img src="@/assets/images/mystery_box.png" alt="">
        <div class="mint-progress flex-center">
          <p>Total <span>{{maxSupply ? maxSupply.toLocaleString() : '5,000'}}</span></p>
          <div class="mint-progress-line">
            <div class="mint-progress-activate" :style="{width: (totalSupply / maxSupply) * 100 + '%'}"><span v-if="totalSupply > 0">{{totalSupply.toLocaleString()}}</span></div>
          </div>
        </div>
        <div v-if="message" class="dialog flex-center-center">{{message}}</div>
      </div>
      <div class="mint-info flex-end" :style="{opacity: (nowTime > roundOneStartTime && nowTime < mintEndTime) ? '1' : '.5'}">
        <div class="mint-info-l flex-center">
          <button class="subtract mint-info-l-btn" @click="subtract" :disabled="!(nowTime > roundOneStartTime && nowTime < mintEndTime)">
            <svg t="1666286409045" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5507" width="18" height="18"><path d="M144 567c-24.3 0-44-19.7-44-44s19.7-44 44-44h736.127c24.3 0 44 19.7 44 44s-19.7 44-44 44H144z" p-id="5508" fill="#ffffff"></path></svg>
          </button>
          <div class="number">{{number}}</div>
          <button class="add mint-info-l-btn"  @click="add" :disabled="!(nowTime > roundOneStartTime && nowTime < mintEndTime)">
            <svg t="1666286370726" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4539" width="18" height="18"><path d="M911.244448 452.317713 563.184241 452.317713 563.184241 104.274902c0-27.465538-22.254863-49.720402-49.721425-49.720402-27.465538 0-49.719378 22.254863-49.719378 49.720402l0 348.043834L115.715977 452.318736c-27.465538 0-49.720402 22.254863-49.720402 49.721425 0 27.465538 22.254863 49.720402 49.720402 49.720402l348.028485 0 0 348.010065c0 27.467585 22.25384 49.722448 49.719378 49.722448 27.466562 0 49.721425-22.254863 49.721425-49.722448L563.185264 551.760562l348.060207 0c27.466562 0 49.720402-22.254863 49.720402-49.720402C960.96485 474.572576 938.71101 452.317713 911.244448 452.317713z" p-id="4540" fill="#ffffff"></path></svg>
          </button>
        </div>
        <div class="mint-info-r flex-end">
          <span>TOTAL PRICE</span>
          <p v-if="nowTime > roundOneStartTime && nowTime < roundTwoStartTime"><span>{{(firstRoundPrice * number).toFixed(2)}}</span>ETH</p>
          <p v-else-if="nowTime > roundTwoStartTime && nowTime < mintEndTime"><span>{{(secondRoundPrice * number).toFixed(2)}}</span>ETH</p>
          <p v-else><span>{{(0.08 * number).toFixed(2)}}</span>ETH</p>
        </div>
      </div>
      <div v-if="mintStatus == 'succeed'" @click="goOn" class="mint-btn mint-btn-continue flex-center-center">Continue</div>
      <button v-else-if="(nowTime > roundOneStartTime && nowTime < mintEndTime)" class="mint-btn mint-btn-activate flex-center-center" @click="mint(address)" :disabled="mintStatus == 'loading'">MINT NOW!</button>
      <div v-else class="mint-btn flex-center-center">not start yet</div>
      <div v-if="!balanceOf" class="owned flex-center-center">
        <p>no items</p>
      </div>
      <div v-else class="owned flex-center-center flex-center-sb">
        <div class="owned-l">Owned Mystery Box:</div>
        <div class="owned-r flex-center">
          <img src="@/assets/images/mystery_box.png" alt="">
          <p>x <span>{{balanceOf}}</span></p>
        </div>
      </div>
      <div class="brand flex-center">
        <div>
          <a href="https://www.bybit.com/en-US/nft/detail/?id=395958" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/bybi.png" alt="" style="height: .22rem">
          </a>
        </div>
        <div>
          <a href="https://www.gate.io/nft/collection/16102/Spola-PASS" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/gate.png" alt="" style="height: .22rem">
          </a>
        </div>
        <div>
          <a href="https://bitkeep.com/en/download" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/bitkeep.png" alt="" style="height: .24rem">
          </a>
        </div>
        <div>
          <a href="https://www.bkex.com/" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/images/bkex.png" alt="">
          </a>
        </div>
        <div>
          <img src="@/assets/images/opensea.png" alt="" >
        </div>
        <div>
          <img src="@/assets/images/element.png" alt="" >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { useStore } from 'vuex'
import { useContract } from '../hooks/useContract'
export default {
  setup(props, { emit }) {
    const store = useStore()
    const {
      roundOneStartTime,
      roundTwoStartTime,
      mintEndTime,
      firstRoundPrice,
      secondRoundPrice,
      totalSupply,
      maxSupply,
      mintStatus,
      tokenQuantity,
      balanceOf,
      message,
      number,
      subtract,
      add,
      setContract,
      mint,
      goOn
    } = useContract()
    const address = computed(() => {
      return store.state.address
    })
    const web3 = computed(() => {
      return store.state.web3
    })
    const network = computed(() => {
      return store.state.network
    })
    const clickEscCode = (e) => {
      if (e.keyCode === 27) {
        close()
      }
    }
    
    const close = () => {
      emit('closeMint')
    }

    watch(web3, async (value) => {
      setContract(value)
    }, {immediate: true})
    
    onMounted(() => {
      window.addEventListener('keydown', clickEscCode)
    })
    onUnmounted(() => {
      window.removeEventListener('keydown', clickEscCode)
    })
    return {
      number,
      address,
      network,
      roundOneStartTime,
      roundTwoStartTime,
      mintEndTime,
      firstRoundPrice,
      secondRoundPrice,
      totalSupply,
      maxSupply,
      mintStatus,
      tokenQuantity,
      balanceOf,
      message,
      nowTime: ref(Math.floor(new Date().getTime() / 1000)),
      subtract,
      add,
      close,
      mint,
      goOn
    }
  }
}
</script>
<style lang="scss" scoped>
.mint {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 9999;
  font-size: 0;
  padding: .96rem 0;
  box-sizing: border-box;
  overflow: auto;
  .mint-content {
    padding: .79rem .2rem .3rem;
    box-sizing: border-box;
    background: #171619;
    border: 1px solid #27262A;
    width: 7rem;
    position: relative;
    margin: auto;
    .close {
      position: absolute;
      top: .16rem;
      left: .2rem;
      width: 1.38rem;
      height: .5rem;
      background: #101013;
      border-radius: .06rem;
      font-size: .2rem;
      color: #778AA2;
      line-height: .13rem;
      cursor: pointer;
      border: .02rem solid #27262A;
      z-index: 19;
    }
    .title {
      text-align: center;
      position: relative;
      text-transform: uppercase;
      span {
        font-size: .32rem;
        font-family: DINPro-Bold, DINPro;
        font-weight: bold;
        color: #FFFFFF;
        line-height: .41rem;
        background: linear-gradient(295deg, #1C42FF 0%, #4BE9F2 50%, #CBFFEC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .sub-title {
      font-size: .2rem;
      font-family: DINPro-Regular, DINPro;
      font-weight: 400;
      color: #778AA2;
      line-height: .32rem;
      margin-top: .12rem;
      text-align: center;
    }
    .progress {
      margin: auto;
      margin-top: .33rem;
      padding-right: .32rem;
      height: .02rem;
      box-sizing: border-box;
      position: relative;
      .progress-line {
        height: .02rem;
        background: rgba($color: #778AA2, $alpha: .2);
        border-radius: .01rem;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 0;
        .progress-activate {
          position: absolute;
          left: 0;
          // right: 0;
          top: 0;
          bottom: 0;
          width: 1.4rem;
          z-index: 9;
          background: linear-gradient(132deg, rgba(28, 66, 255, 1), rgba(181, 0, 255, 1), rgba(255, 71, 71, 1));
          &.progress-activate-after {
            &::after {
              content: '';
              width: .12rem;
              height: .12rem;
              border-radius: .06rem;
              background: rgba(255, 71, 71, 1);
              position: absolute;
              right: -.06rem;
              top: -.05rem;
            }
          }
        }
        
        .line-item {
          height: .02rem;
          position: relative;
          p {
            color: #778AA2;
            line-height: .32rem;
            position: absolute;
            white-space: nowrap;
            top: .05rem;
            font-size: .18rem;
            color: #778AA2;
            line-height: .32rem;
            span {
              font-family: DINPro-Bold;
              font-size: .2rem;
            }
            &.p-activate {
              background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              img {
                display: inline;
              }
            }
            img {
              width: .15rem;
              height: auto;
              margin-right: .05rem;
              display: none;
            }
          }
          &::after {
            content: '';
            width: .08rem;
            height: .08rem;
            border-radius: .04rem;
            background: #2A2D34;
            position: absolute;
            right: -.04rem;
            top: -.03rem;
          }
        }
        .line-1 {
          width: 1.78rem;
          p {
            left: 0;
            text-align: left;
          }
        }
        .line-2 {
          width: 2.84rem;
          p {
            right: -2rem;
            text-align: right;
          }
        }
        &:hover {
          & ~ .close-hint-wrap {
            .close-hint {
              display: block;
            }
          }
        }
      }
      .close-hint-wrap {
        z-index: 2;
        position: absolute;
        right: .12rem;
        top: -.06rem;
        .icon {
          width: .13rem;
          height: .13rem;
          i {
            display: inline-block;
            background: #FF4747;
            border-radius: .02rem;
            height: .13rem;
            width: .02rem;
          }
        }
        &:hover {
          .close-hint {
            display: block;
          }
        }
        .close-hint {
          position: absolute;
          top: -.82rem;
          right: -.18rem;
          display: none;
          img {
            width: 2.25rem;
          }
          p {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            box-sizing: border-box;
            padding: .09rem 0 .22rem;
            text-align: center;
            font-size: 0;
            span {
              font-size: .18rem;
              color: #FFFFFF;
              line-height: .23rem;
              &:first-child {
                font-size: .20rem;
                font-family: DINPro-Bold, DINPro;
                color: #FF4747;
                line-height: .26rem;
              }
            }
          }
        }
      }
    }
    .section {
      height: 4.67rem;
      margin-top: .66rem;
      background: #101013;
      border-radius: .07rem;
      border: 1px solid #27262A;
      padding: .64rem .32rem .18rem .26rem;
      box-sizing: border-box;
      position: relative;
      .dialog {
        position: absolute;
        left: 0;
        // top: 0;
        // bottom: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin: auto;
        width: 5.27rem;
        padding: .4rem .2rem;
        box-sizing: border-box;
        background: #171619;
        border: .01rem solid #FF4747;
        font-size: .2rem;
        font-family: DINPro-Bold, DINPro;
        font-weight: bold;
        color: #FF4747;
        text-transform: uppercase;
        z-index: 99;
      }
      img {
        width: 5.27rem;
        display: block;
        margin: auto;
        height: auto;
      }
      .mint-progress {
        margin-top: .75rem;
        p {
          font-size: .20rem;
          color: #778AA2;
          line-height: .32rem;
          span {
            font-family: DINPro-Bold;
          }
        }
        .mint-progress-line {
          margin-left: .17rem;
          flex: 1;
          position: relative;
          height: .02rem;
          background: rgba($color: #778AA2, $alpha: .2);
          border-radius: .02rem;
        }
        .mint-progress-activate {
          background: #778AA2;
          border-radius: .02rem;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 0;
          span {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: .4rem;
            height: .3rem;
            font-size: .2rem;
            font-family: DINPro-Bold, DINPro;
            color: #778AA2;
            top: -.34rem;
            right: -.2rem;
          }
          // &::after {
          //   content: '';
          //   width: .08rem;
          //   height: .08rem;
          //   border-radius: .04rem;
          //   background: #778AA2;
          //   position: absolute;
          //   right: -.04rem;
          //   top: -.03rem;
          // }
        }
      }
      &.section-success {
        position: relative;
        padding: .56rem 0 .62rem;
        box-sizing: border-box;
        flex-direction: column;
        border-radius: .07rem;
        border: 1px solid #1C42FF;
        overflow: hidden;
        .bg-img {
          position: absolute;
          left: .12rem;
          top: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
        }
        .success-title {
          font-size: .32rem;
          font-family: DINPro-Bold, DINPro;
          font-weight: bold;
          color: #FFFFFF;
          line-height: .41rem;
          background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
        }
        .success-sub-title {
          font-size: .2rem;
          color: #FFFFFF;
          line-height: .32rem;
          span {
            font-family: DINPro-Bold, DINPro;
          }
        }
        .success-box {
          margin-top: .39rem;
          position: relative;
          img {
            width: 4.41rem;
            height: auto;
          }
          .success-num {
            position: absolute;
            bottom: 0;
            right: -.6rem;
            margin-left: 13px;
            font-size: .32rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .41rem;
            span {
              font-family: DINPro-Bold, DINPro;
            }
          }
        }
      }
      &.section-loading {
        flex-direction: column;
        .loader {
          width: .64rem;
          height: .64rem;
          border-radius: 50%;
          display: inline-block;
          border-top: .04rem solid #FFF;
          border-right: .04rem solid transparent;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .loading-title {
          margin-top: .24rem;
          font-size: .28rem;
          color: #FFFFFF;
          line-height: .41rem;
        }

      }
    }
    .mint-info {
      margin-top: .4rem;
      justify-content: center;
      opacity: .5;
      justify-content: space-between;
      .mint-info-l {
        button {
          border: none;
          background: none;
          outline: none;
        }
        .number {
          width: 1.16rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: .44rem;
          font-family: DINPro-Bold;
          color: #FFFFFF;
        }
        .mint-info-l-btn {
          cursor: pointer;
          width: .63rem;
          height: .52rem;
          background: #222125;
          border-radius: .08rem;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: .31rem;
            height: auto;
          }
          &[disabled] {
            cursor: not-allowed;
          }
        }
      }
      .mint-info-r {
        margin-bottom: .02rem;
        span {
          font-size: .20rem;
          font-family: DINPro-Bold, DINPro;
          color: #778AA2;
          line-height: .45rem;
        }
        p {
          margin-left: .24rem;
          font-size: .28rem;
          color: #FFFFFF;
          line-height: .36rem;
          padding-right: .12rem;
          span {
            font-size: .44rem;
            font-family: DINPro-Bold, DINPro;
            color: #FFFFFF;
            line-height: .56rem;
            margin-right: .09rem;
          }
        }
      }
    }
    .mint-btn {
      background: none;
      border: none;
      display: flex;
      width: 100%;
      margin-top: .52rem;
      height: .62rem;
      border-radius: .07rem;
      font-size: .32rem;
      font-family: DINPro-Bold, DINPro;
      background: #101013;
      color: #778AA2;
      opacity: .35;
      text-transform: uppercase;
      cursor: not-allowed;
      &.mint-btn-activate {
        background: linear-gradient(132deg, #FF4747 0%, #B500FF 52%, #1C42FF 100%);
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
      }
      &.mint-btn-continue {
        background: #1C42FF;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
      }
    }
    .owned {
      margin-top: .3rem;
      height: 1.58rem;
      background: #101013;
      border-radius: .09rem;
      border: 1px solid #27262A;
      padding: .21rem .6rem;
      box-sizing: border-box;
      p {
        font-size: .24rem;
        font-family: DINPro-Bold, DINPro;
        color: #778AA2;
        text-align: center;
        opacity: .35;
        font-family: DINPro-Bold;
      }
      .owned-l {
        font-size: .24rem;
        font-family: DINPro-Bold, DINPro;
        color: #778AA2;
        line-height: .32rem;
      }
      .owned-r {
        img {
          width: 2.2rem;
          height: auto;
          margin-right: .29rem;
        }
        p {
          font-size: .24rem;
          font-family: DINPro-Regular, DINPro;
          color: #778AA2;
          line-height: .31rem;
          opacity: 1;
          span {
            font-family: 'DINPro-Bold';
          }
        }
      }
    }
    .brand {
      margin-top: .22rem;
      justify-content: space-between;
      div {
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        a {
          display: inline-block;
        }
        img {
          height: .25rem;
          width: auto;
        }
      }
    }
  }
}
</style>