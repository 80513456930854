/* eslint-disable */ 
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos'
// import VConsole from 'vconsole';
// const vConsole = new VConsole();
createApp(App).AOS = new AOS.init({ 
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  offset: 0,
  duration: 750,
  easing: 'ease-out',
  once: true
})
import './assets/css/reset.scss'
import './assets/css/main.scss'
import './libs/rem'
import 'aos/dist/aos.css'
createApp(App).use(store).use(router).mount('#app')
