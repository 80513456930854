<template>
  <div class="number-grow-warp">
    <span
      ref="numberGrow"
      :data-time="time"
      class="number-grow"
      :data-value="value"
      >{{num}}</span
    >
  </div>
</template>
<script>
import { defineComponent, onMounted, watch, ref } from 'vue'
export default defineComponent({
  props: {
    time: {
      type: Number,
      default: 4
    },
    value: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const num = ref(0)
    onMounted(() => {
      numberGrow()
    })
    const numberGrow = () => {
      const step = (props.value * 10) / (props.time * 1000)
      let current = 0
      let start = 0
      let t = setInterval(() => {
        start += step
        if (start > props.value) {
          clearInterval(t)
          start = props.value
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        num.value = current
          .toFixed(0)
          .toString()
          .replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
      }, 0.01)
    }
    
    watch(props, () => {
      numberGrow()
    })

    return {
      numberGrow,
      num
    }
  }
})
</script>


<style>
.number-grow-warp {
  transform: translateZ(0);
}
.number-grow {
  margin-top: 41px;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 41px;
  background: linear-gradient(299deg, #1C42FF 0%, #4BE9F2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

